import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivateViewModel, UserProfile } from '../../_shared/Models';
import { Application } from '../_entities/Application';
import { ApplicationUser } from '../_entities/ApplicationUser';
import { FileResult } from '../_entities/FileResult';
import { KeyTenantModel } from '../_entities/KeyTenantModel';

@Injectable({
   providedIn: 'root',
})
export class UserService {
   private baseUrl: string = window.location.origin;

   constructor(private http: HttpClient) {}

   getUserToActivate(userId: string): Observable<UserProfile> {
      return this.http.get<UserProfile>(`${this.baseUrl}/api/useractive/getusertoactivate/${userId}`);
   }

   activateUser(model: ActivateViewModel): Observable<any> {
      return this.http.put(`${this.baseUrl}/api/useractive/activate`, model);
   }

   grantApplicationAccess(userIdentifier: string, applicationId: number): Observable<ApplicationUser> {
      return this.http.post<ApplicationUser>(`${this.baseUrl}/api/admin/user/${userIdentifier}/grantaccess/${applicationId}`, '');
   }

   revokeApplicationAccess(userIdentifier: string, applicationId: number): Observable<ApplicationUser> {
      return this.http.post<ApplicationUser>(`${this.baseUrl}/api/admin/user/${userIdentifier}/revokeaccess/${applicationId}`, '');
   }

   getApplicationsByUser(userId: number): Observable<ApplicationUser[]> {
      return this.http.get<ApplicationUser[]>(`${this.baseUrl}/api/admin/user/getapplicationsbyuser/${userId}`);
   }

   getUserApplications(): Observable<ApplicationUser[]> {
      return this.http.get<ApplicationUser[]>(`${this.baseUrl}/api/admin/user/getuserapplications`);
   }

   getAvailableApplications(userId: number): Observable<Application[]> {
      return this.http.get<Application[]>(`${this.baseUrl}/api/admin/user/getavailableapplications/${userId}`);
   }

   saveUser(userProfile: UserProfile): Observable<UserProfile> {
      return this.http.post<UserProfile>(`${this.baseUrl}/api/admin/user`, userProfile);
   }

   getUserDetails(userId: string): Observable<UserProfile> {
      return this.http.get<UserProfile>(`${this.baseUrl}/api/admin/user/getuserdetail/${userId}`);
   }
   checkIsCustomer(keyTenants: KeyTenantModel): Observable<KeyTenantModel> {
      return this.http.post<KeyTenantModel>(`${this.baseUrl}/api/fulfillment/baseData/checkiscustomer`, keyTenants);
   }

   getBuyerImportedFile(): Observable<FileResult> {
      return this.http.get<FileResult>(`${this.baseUrl}/api/admin/user/getbuyerfile`);
   }

   getCustomerImportedFile(): Observable<FileResult> {
      return this.http.get<FileResult>(`${this.baseUrl}/api/admin/user/getcustomerfile`);
   }


   adminNotificationretRetrieve(id: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}/api/admin/user/${id}/tenderNotificationRetrieve`);
   }

   adminNotificationretRetrieveById(id: number): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}/api/admin/user/${id}/tenderNotificationById`);
   }

   adminNotificationMarkAsRead(id: number): Observable<any> {
      return this.http.put<any>(`${this.baseUrl}/api/admin/user/${id}/markAsRead`, '');
   }
   updateUserProfile(userProfile: UserProfile): Observable<UserProfile> {
      return this.http.put<UserProfile>(`${this.baseUrl}/api/admin/user/update`, userProfile);
   }
}
