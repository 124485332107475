import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
   selector: 'app-page-header',
   templateUrl: './page-header.component.html',
   styleUrls: [],
})
export class PageHeaderComponent {
   @Input() title: string;
   @Input() subTitle: string;

   constructor(private location: Location) {}

   onBack() {
      this.location.back();
   }
}
