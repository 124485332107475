import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogStorageService {
  constructor() { }

  readFile(): string {
    let val: string = localStorage.getItem("log") || "";

    if(val === ""){
      return val;
    } else {
      return atob(val);
    }
  }

  writeFile(data: string): void {
    localStorage.setItem("log", btoa(data));
  }

  log(text: string): any {
    let currentVal: string = this.readFile();

    let newVal: string = "";
    if(currentVal === ""){
      newVal = text;
    } else {
      newVal = currentVal + "\n\n" + text;
    }

    this.writeFile(newVal);
  }
}
