import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Confirmation } from './confirmation';
import { ConfirmationPopupService } from './confirmation-popup.service';

@Component({
    selector: 'confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
})
export class ConfirmationPopupComponent implements OnDestroy {
    private CONFIRM_DIALOG_HIGHT = 173;
    visible = false;
    public isOpened: boolean = false;
    public confirmDialogHeight: number = this.CONFIRM_DIALOG_HIGHT;
    public confirmation: Confirmation = {} as Confirmation;
    private confirmationPopupSubscription = {} as Subscription;

    constructor(private confirmationPopupService: ConfirmationPopupService) {
        this.subscribeToToastActivation();
        this.visible = true;
    }

    hide() {
        this.isOpened = false;
    }

    accept() {
        if (this.confirmation.acceptEvent) {
            this.confirmation.acceptEvent.emit();
        }

        this.hide();
    }

    reject() {
        if (this.confirmation.rejectEvent) {
            this.confirmation.rejectEvent.emit();
        }

        this.hide();
    }

    cancel() {
        if (this.confirmation.cancelEvent) {
            this.confirmation.cancelEvent.emit();
        }
        this.hide();
    }

    ngOnDestroy(): void {
        this.confirmationPopupSubscription.unsubscribe();
    }

    private subscribeToToastActivation(): void {
        this.confirmationPopupSubscription =
            this.confirmationPopupService.confirmationPopup$.subscribe(
                (confirmation: Confirmation) => {
                    if (!confirmation) {
                        this.hide();
                        return;
                    }

                    this.confirmation = confirmation;
                    if (this.confirmation.accept) {
                        this.confirmation.acceptEvent = new EventEmitter();
                        this.confirmation.acceptEvent.subscribe(
                            this.confirmation.accept
                        );
                    }

                    if (this.confirmation.reject) {
                        this.confirmation.rejectEvent = new EventEmitter();
                        this.confirmation.rejectEvent.subscribe(
                            this.confirmation.reject
                        );
                    }

                    if (this.confirmation.cancel) {
                        this.confirmation.cancelEvent = new EventEmitter();
                        this.confirmation.cancelEvent.subscribe(
                            this.confirmation.cancel
                        );
                    }
                    this.isOpened = true;
                    this.adjustWarningHeight(this.confirmation.message);
                }
            );
    }

    private adjustWarningHeight(message?: string): void {
        this.confirmDialogHeight = this.CONFIRM_DIALOG_HIGHT;
        if (message?.length && message.length > 54) {
            this.confirmDialogHeight =
                this.confirmDialogHeight + (message.length / 50) * 20;
        }
    }
}
