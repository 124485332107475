import { Component } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthorizeService } from '../api-authorization/authorize.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
})
export class AppComponent {
   title = 'app';
   idleVisible = false;

   constructor(private idle: Idle, private authorizeService: AuthorizeService){
      //this.configureIdleTimeout();
      //this.startIdleTimeout()
   }
   private configureIdleTimeout(): void {
      this.idle.setIdle(5); // 5 seconds of inactivity to trigger idle timeout
      this.idle.setTimeout(60 * 60); // 30 seconds to wait after idle timeout to trigger the timeout event
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Set the sources that interrupt the idle timer (e.g., mousemove, keydown, etc.)
    }
  
    private startIdleTimeout(): void {
      this.idle.watch();
      this.idle.onTimeout.subscribe(() => {
        // Perform actions when the idle timeout is triggered
        // For example, log out the user, display a modal, etc.
        this.idleVisible = true;
        // console.log('Idle timeout triggered');
      });
    }
  
    accept(){
      this.authorizeService.autoLogout();
      this.idleVisible = false;
    }
  
    cancel(){
      this.authorizeService.autoLogout();
      this.idleVisible = false;
    }
}
