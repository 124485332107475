<div id="map-container" class="map-container"
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="mapClicked($event)">
</div>

<div class="bottom-text">
  <p><b>Longitude:</b> {{ longitude }}</p>
  <p><b>Latitude:</b> {{ latitude }}</p>
</div>
