import { Component, OnInit } from '@angular/core';
import { AuthorizeService, AuthenticationResultStatus } from '../authorize.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoginActions, LogoutActions, QueryParameterNames, ApplicationPaths, ReturnUrlType } from '../api-authorization.constants';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ConfirmationPopupService } from '../confirmation-popup/confirmation-popup.service';
import { take } from 'rxjs/operators';
import { LogStorageService } from './../../app/_shared/log-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
   isSuccessed: boolean = true;
   idleState = 'Not started.';
   timedOut = false;
   isIdle = false;
   lastPing?: Date | null = null;
   subscription: Subscription = new Subscription();

   public message = new BehaviorSubject<string>(null);

   constructor(
      private authorizeService: AuthorizeService,
      private activatedRoute: ActivatedRoute, 
      private router: Router,
      private keepalive: Keepalive,
      private idle: Idle,
      private confirmationPopupService: ConfirmationPopupService,
      private log: LogStorageService,
      private notification: NzNotificationService
   ) {
      this.sessionTimeOutInit(this.getReturnUrl());
   }

   async ngOnInit() {
      const action = this.activatedRoute.snapshot.url[1];
      // this.log.log(action.path);
      // const action = { path: 'login-failed' };
      switch (action.path) {
         case LoginActions.Login:
            await this.login(this.getReturnUrl());
            break;
         case LoginActions.LoginCallback:
            await this.processLoginCallback();
            break;
         case LoginActions.LoginFailed:
            this.isSuccessed = false;
            const message = this.activatedRoute.snapshot.queryParamMap.get(QueryParameterNames.Message);
            this.message.next(message);
            break;
         case LoginActions.Profile:
            this.redirectToProfile();
            break;
         case LoginActions.Register:
            this.redirectToRegister();
            break;
         default:
            this.isSuccessed = false;
            throw new Error(`Invalid action '${action}'`);
      }
   }

   private async login(returnUrl: string): Promise<void> {
      const state: INavigationState = { returnUrl };
      const result = await this.authorizeService.signIn(state);
      this.message.next(undefined);
      switch (result.status) {
         case AuthenticationResultStatus.Redirect:
            break;
         case AuthenticationResultStatus.Success:
            await this.navigateToReturnUrl(returnUrl);
            break;
         case AuthenticationResultStatus.Fail:
            await this.router.navigate(ApplicationPaths.LoginFailedPathComponents, {
               queryParams: {
                  [QueryParameterNames.Message]: result.message,
               },
            });
            break;
         default:
            throw new Error(`Invalid status result ${(result as any).status}.`);
      }
   }

   async logout(): Promise<void> {
      const url = window.location.href;
      const result = await this.authorizeService.completeSignOut(url);
      switch (result.status) {
         case AuthenticationResultStatus.Redirect:
            throw new Error('Should not redirect.');
         case AuthenticationResultStatus.Success:
            await this.navigateToReturnUrl(this.getLogoutUrl());
            // await this.navigateToReturnUrl(this.getReturnUrl(result.state));
            break;
         case AuthenticationResultStatus.Fail:
            await this.navigateToReturnUrl(this.getLogoutUrl());
            this.message.next(result.message);
            break;
         default:
            await this.navigateToReturnUrl(this.getLogoutUrl());
            throw new Error('Invalid authentication result status.');
      }
   }

   private async processLoginCallback(): Promise<void> {
      const url = window.location.href;
      const result = await this.authorizeService.completeSignIn(url);
      switch (result.status) {
         case AuthenticationResultStatus.Redirect:
            // There should not be any redirects as completeSignIn never redirects.
            throw new Error('Should not redirect.');
         case AuthenticationResultStatus.Success:
            this.isSuccessed = true;
            await this.navigateToReturnUrl(this.getReturnUrl(result.state));
            break;
         case AuthenticationResultStatus.Fail:
            this.isSuccessed = false;
            this.message.next(result.message);
            break;
      }
   }

   reset() {
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
   }

   sessionTimeOutInit(returnurl) {
      this.authorizeService.getSessionSetting().subscribe((res) => {
         this.idle.setIdle((res.sessionTimeoutInMin - res.sessionTimeoutWarningInMin) * 60);
         this.idle.setTimeout(res.sessionTimeoutInMin * 60);

         // this.idle.setIdle(15);
         // this.idle.setTimeout(10);

         this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

         const idleTimeoutEnd = this.idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            // console.log(this.idleState);
            this.reset();
         });

         const idleTimeoutSub = this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.isIdle = true;
            this.confirmationPopupService.confirm({
               message: 'Session expired. You will be redirected to login page',
               buttonTemplate: 'ok',
               accept: () => {
                  this.authorizeService.completeSignOut(returnurl);
               },
               cancel: () => {
                  this.authorizeService.completeSignOut(returnurl);
               },
            });
         });

         const idleStartSub = this.idle.onIdleStart.subscribe(() => {
            this.confirmationPopupService.confirm({
               message: 'Your session will expire shortly. Click OK button to continue the session.',
               buttonTemplate: 'ok',
               accept: () => {
                  this.reset();
                  this.authorizeService.getAccessToken().subscribe((res) => {
                     /*  this.loginService.setTokens(
                              res.accessToken,
                              res.refreshToken
                          );*/
                  });
               },
            });
         });

         const timeoutWarningSub = this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
         });

         this.keepalive.interval(15);
         const keepalivePingSub = this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

         this.reset();

         this.subscription.add(idleTimeoutEnd);
         this.subscription.add(idleTimeoutSub);
         this.subscription.add(idleStartSub);
         this.subscription.add(timeoutWarningSub);
         this.subscription.add(keepalivePingSub);
      });
   }

   private redirectToRegister(): any {
      this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?returnUrl=${encodeURI('/' + ApplicationPaths.Login)}`);
   }

   private redirectToProfile(): void {
      this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
   }

   private async navigateToReturnUrl(returnUrl: string) {
      // It's important that we do a replace here so that we remove the callback uri with the
      // fragment containing the tokens from the browser history.
      await this.router.navigateByUrl(returnUrl, {
         replaceUrl: true,
      });

      this.sessionTimeOutInit(returnUrl);
   }

   private getReturnUrl(state?: INavigationState): string {
      const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
      // If the url is comming from the query string, check that is either
      // a relative url or an absolute url
      if (fromQuery && !(fromQuery.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(fromQuery) || fromQuery === '/')) {
         // This is an extra check to prevent open redirects.
         throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
      }
      return (state && state.returnUrl) || fromQuery || ApplicationPaths.DefaultLoginRedirectPath;
   }

   private getLogoutUrl(): string {
      return ApplicationPaths.LogOut;
   }

   private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
      // It's important that we do a replace here so that when the user hits the back arrow on the
      // browser they get sent back to where it was on the app instead of to an endpoint on this
      // component.
      const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
      window.location.replace(redirectUrl);
   }

   loginFailEmail(): void {
      this.authorizeService.loginIssueComplain().subscribe(
         (res: any) => {
            this.notification.success('Success', "Inform to Admin Successfully!", { nzPlacement: 'bottomRight' });
         },
         (err: any) => {
            this.notification.error('Success', "Inform to Admin Failed!", { nzPlacement: 'bottomRight' });
            console.error(err);
         }
      );
   }

   ngOnDestroy(): void {
      this.subscription.unsubscribe();
   }
}

interface INavigationState {
   [ReturnUrlType]: string;
}
