import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../_shared/shared.module';
import { AppNgZorroAntdModule } from '../_shared/nz/nz.module';

@NgModule({
   declarations: [],
   imports: [
      CommonModule,
      SharedModule,
      AppNgZorroAntdModule,
      RouterModule.forChild([
         {
            path: 'super',
            loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
         },
         {
            path: 'consumer',
            loadChildren: () => import('./consumer/consumer.module').then((m) => m.ConsumerModule),
         },
         {
            path: 'supplier-management',
            loadChildren: () => import('./supplier-management/supplier-management.module').then((m) => m.SupplierManagementModule),
         },
      ]),
   ],
})
export class AdminModule {}
