import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { RegistrationStatus, UserType } from '../_shared/Enums';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
   constructor(private authorizeService: AuthorizeService, private router: Router) {}

   ngOnInit() {
      const userProfile = this.authorizeService.getFullUserProfile();
      this.authorizeService.getCurrentTenant().subscribe((tenant) => {
         let homeUrl = '';
         // console.log(userProfile.userType );
         if (tenant.registrationStatus === RegistrationStatus.Approved) {
            if (
               userProfile.userType === UserType.Buyer ||
               userProfile.userType === UserType.Supplier ||
               userProfile.userType === UserType.SupplierOperative ||
               userProfile.userType === UserType.FulfillmentCustomer
            ) {
              // console.log('consumer');
               homeUrl = '/admin/consumer/home';
            } else if (userProfile.userType === UserType.Admin) {
               homeUrl = '/admin/super/home';
            } else if (userProfile.userType === UserType.Auditor) {
               homeUrl = '/certification/consumer/home';
            } else if (userProfile.userType === UserType.Consultant) {
               homeUrl = '/certification/consultant/home';
            }
            else if (userProfile.userType === '30') {
               homeUrl = '/admin/consumer/home';
               }

            else {
               throw Error('Unknown User Category');
            }
         } else if (tenant.registrationStatus === RegistrationStatus.Inprogress || tenant.registrationStatus === RegistrationStatus.Invited) {
            //  homeUrl = '/Account/consumer/profile';
            homeUrl = '/admin/consumer/home';
         } else if (tenant.registrationStatus === RegistrationStatus.Completed) {
            homeUrl = '/admin/consumer/pre-approval';
         } else {
            throw Error('Unknown Registration Status for Tenant');
         }
         this.router.navigate([homeUrl]);
      });
   }
}
