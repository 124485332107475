import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../../admin/_services/user.service';
import { getCurrentUser } from './../../_shared/Utils';
import { UserType } from '../../_shared/Enums';

@Component({
   selector: 'app-common-navigate',
   templateUrl: './common-navigate.component.html',
   styleUrls: ['./common-navigate.component.css'],
})
export class CommonNavigateComponent implements OnInit {
   grantedApplications: any[] = [];
   userData: any;
   isVisible: boolean = true;
   blockedURI: string[] = ['/consumer/signup', '/consumer/user-activate', '/consumer/pre-approval', '/consumer/user-registration'];

   showCompanyProfile = true;
   hideConnectionUserMangeProfile = true;
   showBaseData = true;
   onlyModules = false;
   idleVisible = false;
   screenWidth: number = 0;

   @HostListener('window:resize', ['$event'])
   onResize(event: Event) {
      this.screenWidth = window.innerWidth;
   }

   constructor(private router: Router, private userService: UserService) {
      this.screenWidth = window.innerWidth;
   }

   ngOnInit(): void {
      this.hideCommonNavigateByURI();
      this.getUserApplications();
      this.getUserDetails();
   }

   getUserDetails(): void {
      this.userData = getCurrentUser();

      if (this.userData.userType === UserType.Supplier) {
         this.showBaseData = false;
      }

      if (this.userData.userType === UserType.SupplierOperative || this.userData.userType === UserType.FulfillmentCustomer) {
         this.hideConnectionUserMangeProfile = false;
         this.showBaseData = false;
         this.onlyModules = true;
         this.showCompanyProfile = false;
      }
   }

   getUserApplications(): void {
      this.userService.getUserApplications().subscribe((applications) => {
         this.grantedApplications = applications;
      });
   }

   viewHome(): void {
      this.router.navigate(['admin/consumer/home']);
   }

   navigateTo(uri: string): void {
      this.router.navigate([uri]);
   }

   hideCommonNavigateByURI(): void {
      const uri = this.router.url;
      this.blockedURI.forEach((ele: string) => {
         if (uri.includes(ele)) {
            this.isVisible = false;
         }
      });
   }
}
