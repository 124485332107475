<div *ngIf="!(message | async)">
   <nz-result
      nzStatus="info"
      nzTitle="FM Navigate"
      nzSubTitle="Logging you out, please wait"
   >
   </nz-result>
   <ng-template #indicatorTemplate
      ><em nz-icon nzType="lock" [nzSpin]="true" style="font-size: 50px;"></em>
   </ng-template>
   <div style="text-align: center;">
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
   </div>
</div>
<div *ngIf="message | async">
   <nz-result
      nzStatus="success"
      nzTitle="FM Navigate"
      nzSubTitle="You have logged out!"
   >
   </nz-result>
   <ng-template #indicatorTemplate>
      <div>
         <em nz-icon nzType="unlock" style="font-size: 50px;"></em>
      </div>
      <div style="margin-top: 10px;">
         <a
            class="nav-link text-dark"
            [routerLink]="['/authentication/login']"
            title="Login"
            >Login again</a
         >
      </div>
   </ng-template>
   <div style="text-align: center;">
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
   </div>
</div>
