import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { User } from 'oidc-client';
import { Asset } from '../_entities/_shared/Asset';
import { RequestStatus, EntityType } from '../_entities/Enums';
import { Requests } from '../_entities/Requests';
import { WorkDetail } from '../_entities/WorkDetail';
import { Priority } from '../_entities/Priority';
import { Inspection } from '../_entities/Inspection';
import { FileResult } from '../_entities/FileResult';
import { Message } from '../_entities/Message';
import { Attachment } from '../_entities/Attachment';

export enum FullfillmentSyncTypes {
   DeleteAttachment = 10,
   UpdateRequest = 20,
   UpdateWorkDetail = 30,
   WorkprogressDocumentUpload = 40,
   AddInspection = 50,
   SendMessage = 60,
}

export const updateRequestById = (requests: Requests[], payload: Requests) => {
   let syncJob: any = null;
   for (const counter in requests) {
      if (requests[counter].id === payload.id) {
         for (const key of Object.keys(payload)) {
            requests[counter][key] = payload[key];
         }
         syncJob = {
            requestData: payload,
            syncType: FullfillmentSyncTypes.UpdateRequest,
         };
         break;
      }
   }
   return [requests, syncJob];
};

const deleteAttachment = (currentAttachments: Attachment[], payload: DeleteRequestAttachment, syncJobs: any[]) => {
   for (const aCounter in currentAttachments) {
      if (currentAttachments[aCounter].file.id === payload.attachmentId) {
         currentAttachments.splice(Number(aCounter), 1);
         syncJobs.push({
            requestId: payload.requestId,
            attachmentId: payload.attachmentId,
            syncType: FullfillmentSyncTypes.DeleteAttachment,
         });
      }
   }
   return [currentAttachments, syncJobs];
};

const pushToSyncJobs = (syncJobs, syncJob) => {
   if (syncJob) {
      syncJobs.push(syncJob);
   }
   return syncJobs;
};

export interface RequestByStatusItem {
   status: RequestStatus;
   requests: Requests[];
}

export interface SiteAttachmentItem {
   requestId: number;
   attachments: File[];
}

export interface DeleteRequestAttachment {
   requestId: number;
   attachmentId: number;
   syncType?: FullfillmentSyncTypes;
}

export interface AssetListItem {
   buildingId: number;
   assets: Asset[];
}

export interface RequestWorkDetail {
   requestId: number;
   workdetail: WorkDetail;
}

export interface SupplierUser {
   email: string;
   user: any;
}

export interface PriorityItem {
   tenantId: number;
   priority: Priority[];
}

export interface InspectionListItem {
   workdetailId: number;
   inspections: Inspection[];
}

export interface FileResultItem {
   workdetailId: number;
   fileResults: FileResult[];
   type: EntityType;
}

export interface RequestMessagesItem {
   requestId: number;
   messages: Message[];
}

export interface FullfillmentStateModel {
   allrequests: Requests[];
   siteAttachments: SiteAttachmentItem[];
   siteOperatives: User[];
   assetList: AssetListItem[];
   requestWorkDetails: RequestWorkDetail[];
   supplierUsers: SupplierUser[];
   priorityItems: PriorityItem[];
   inspectionList: InspectionListItem[];
   fileResults: FileResultItem[];
   requestMessages: RequestMessagesItem[];
   syncJobs: any[];
}

@State<FullfillmentStateModel>({
   name: 'fullfillment',
   defaults: {
      allrequests: [],
      siteAttachments: [],
      siteOperatives: [],
      assetList: [],
      requestWorkDetails: [],
      supplierUsers: [],
      priorityItems: [],
      inspectionList: [],
      fileResults: [],
      requestMessages: [],
      syncJobs: [],
   },
})
@Injectable()
export class FullfillementState {
   @Receiver()
   public static setAllrequests({ patchState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<Requests[]>) {
      patchState({
         allrequests: payload,
      });
   }

   @Receiver()
   public static setSiteOperatives({ patchState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<User[]>) {
      patchState({
         siteOperatives: payload,
      });
   }

   @Receiver()
   public static setSyncJob({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<any>) {   
      const state = getState();
      let syncJobs: any[] = [];
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      syncJobs = pushToSyncJobs(syncJobs, payload);
      patchState({
         syncJobs: syncJobs,
      });
   }

   @Receiver()
   public static deleteAllSyncJob({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<any>) {   
      const state = getState();
      let syncJobs: any[] = JSON.parse(JSON.stringify(state.syncJobs));
      syncJobs = syncJobs.filter((ele: any) => JSON.stringify(ele) !== JSON.stringify(payload));
      patchState({
         syncJobs: syncJobs,
      });
   }

   @Receiver()
   public static setSupplierUser({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<SupplierUser>) {
      const state = getState();
      let supplierUsers: SupplierUser[] = [];
      if (state.supplierUsers) {
         supplierUsers = JSON.parse(JSON.stringify(state.supplierUsers));
      }
      if (supplierUsers && supplierUsers.length > 0) {
         let updated = false;
         for (const counter in supplierUsers) {
            if (supplierUsers[counter].email === payload.email) {
               updated = true;
               supplierUsers[counter].email = payload.email;
               supplierUsers[counter].user = payload.user;
               break;
            }
         }
         if (!updated) {
            supplierUsers.push(payload);
         }
      } else {
         supplierUsers.push(payload);
      }
      patchState({ ...state, supplierUsers: supplierUsers });
   }

   @Receiver()
   public static setRequestMessage({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<RequestMessagesItem>) {
      const state = getState();
      let requestMessages: RequestMessagesItem[] = [];
      if (state.requestMessages) {
         requestMessages = JSON.parse(JSON.stringify(state.requestMessages));
      }
      if (requestMessages && requestMessages.length > 0) {
         let updated = false;
         for (const counter in requestMessages) {
            if (requestMessages[counter].requestId === payload.requestId) {
               updated = true;
               requestMessages[counter].requestId = payload.requestId;
               requestMessages[counter].messages = payload.messages;
               break;
            }
         }
         if (!updated) {
            requestMessages.push(payload);
         }
      } else {
         requestMessages.push(payload);
      }
      patchState({ ...state, requestMessages: requestMessages });
   }

   @Receiver()
   public static addMessageToRequest({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<RequestMessagesItem>) {
      const state = getState();
      let requestMessages: RequestMessagesItem[] = [];
      let syncJobs: any[] = [];
      if (state.requestMessages) {
         requestMessages = JSON.parse(JSON.stringify(state.requestMessages));
      }
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      if (requestMessages && requestMessages.length > 0) {
         let updated = false;
         for (const counter in requestMessages) {
            if (requestMessages[counter].requestId === payload.requestId) {
               updated = true;
               requestMessages[counter].messages.push(payload.messages[0]);
               syncJobs.push({
                  message: payload.messages[0],
                  syncType: FullfillmentSyncTypes.SendMessage,
               });
               break;
            }
         }
         if (!updated) {
            requestMessages.push(payload);
         }
      } else {
         requestMessages.push(payload);
      }
      patchState({ ...state, requestMessages: requestMessages, syncJobs: syncJobs });
   }

   @Receiver()
   public static addRequestMessage({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<RequestMessagesItem>) {
      const state = getState();
      let requestMessages: RequestMessagesItem[] = [];
      if (state.requestMessages) {
         requestMessages = JSON.parse(JSON.stringify(state.requestMessages));
      }
      if (requestMessages && requestMessages.length > 0) {
         let updated = false;
         for (const counter in requestMessages) {
            if (requestMessages[counter].requestId === payload.requestId) {
               updated = true;
               requestMessages[counter].messages = payload.messages;
               break;
            }
         }
         if (!updated) {
            requestMessages.push(payload);
         }
      } else {
         requestMessages.push(payload);
      }
      patchState({ ...state, requestMessages: requestMessages });
   }

   @Receiver()
   public static setPriorityItem({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<PriorityItem>) {
      const state = getState();
      let priorityItems: PriorityItem[] = [];
      if (state.priorityItems) {
         priorityItems = JSON.parse(JSON.stringify(state.priorityItems));
      }
      if (priorityItems && priorityItems.length > 0) {
         let updated = false;
         for (const counter in priorityItems) {
            if (priorityItems[counter].tenantId === payload.tenantId) {
               updated = true;
               priorityItems[counter].tenantId = payload.tenantId;
               priorityItems[counter].priority = payload.priority;
               break;
            }
         }
         if (!updated) {
            priorityItems.push(payload);
         }
      } else {
         priorityItems.push(payload);
      }
      patchState({ ...state, priorityItems: priorityItems });
   }

   @Receiver()
   public static setInspectionItem({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<InspectionListItem>) {
      const state = getState();
      let inspectionList: InspectionListItem[] = [];
      if (state.inspectionList) {
         inspectionList = JSON.parse(JSON.stringify(state.inspectionList));
      }
      if (inspectionList && inspectionList.length > 0) {
         let updated = false;
         for (const counter in inspectionList) {
            if (inspectionList[counter].workdetailId === payload.workdetailId) {
               updated = true;
               inspectionList[counter].workdetailId = payload.workdetailId;
               inspectionList[counter].inspections = payload.inspections;
               break;
            }
         }
         if (!updated) {
            inspectionList.push(payload);
         }
      } else {
         inspectionList.push(payload);
      }
      patchState({ ...state, inspectionList: inspectionList });
   }

   @Receiver()
   public static addInspectionToWorkDetails({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<InspectionListItem>) {
      const state = getState();
      let inspectionList: InspectionListItem[] = [];
      let syncJobs: any[] = [];
      if (state.inspectionList) {
         inspectionList = JSON.parse(JSON.stringify(state.inspectionList));
      }
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      syncJobs.push({
         inspection: payload.inspections[0],
         syncType: FullfillmentSyncTypes.AddInspection,
      });
      if (inspectionList && inspectionList.length > 0) {
         let updated = false;
         for (const counter in inspectionList) {
            if (inspectionList[counter].workdetailId === payload.workdetailId) {
               updated = true;
               inspectionList[counter].inspections.push(payload.inspections[0]);
               break;
            }
         }
         if (!updated) {
            inspectionList.push(payload);
         }
      } else {
         inspectionList.push(payload);
      }
      patchState({ ...state, inspectionList: inspectionList, syncJobs: syncJobs });
   }

   @Receiver()
   public static setFileResultItem({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<FileResultItem>) {
      const state = getState();
      let fileResultItems: FileResultItem[] = [];
      if (state.priorityItems) {
         fileResultItems = JSON.parse(JSON.stringify(state.fileResults));
      }
      if (fileResultItems && fileResultItems.length > 0) {
         let updated = false;
         for (const counter in fileResultItems) {
            if (fileResultItems[counter].workdetailId === payload.workdetailId && fileResultItems[counter].type === payload.type) {
               updated = true;
               fileResultItems[counter].workdetailId = payload.workdetailId;
               fileResultItems[counter].fileResults = payload.fileResults;
               break;
            }
         }
         if (!updated) {
            fileResultItems.push(payload);
         }
      } else {
         fileResultItems.push(payload);
      }
      patchState({ ...state, fileResults: fileResultItems });
   }

   @Receiver()
   public static setAssetItem({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<AssetListItem>) {
      const state = getState();
      let assetList: AssetListItem[] = [];
      if (state.assetList) {
         assetList = JSON.parse(JSON.stringify(state.assetList));
      }
      if (assetList && assetList.length > 0) {
         let updated = false;
         for (const counter in assetList) {
            if (assetList[counter].buildingId === payload.buildingId) {
               updated = true;
               assetList[counter].buildingId = payload.buildingId;
               assetList[counter].assets = payload.assets;
               break;
            }
         }
         if (!updated) {
            assetList.push(payload);
         }
      } else {
         assetList.push(payload);
      }
      patchState({ ...state, assetList: assetList });
   }

   @Receiver()
   public static setRequestWorkDetail({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<RequestWorkDetail>) {
      const state = getState();
      let requestWorkDetails: RequestWorkDetail[] = [];
      if (state.requestWorkDetails) {
         requestWorkDetails = JSON.parse(JSON.stringify(state.requestWorkDetails));
      }
      if (requestWorkDetails && requestWorkDetails.length > 0) {
         let updated = false;
         for (const counter in requestWorkDetails) {
            if (requestWorkDetails[counter].requestId === payload.requestId) {
               updated = true;
               requestWorkDetails[counter].requestId = payload.requestId;
               requestWorkDetails[counter].workdetail = payload.workdetail;
               break;
            }
         }
         if (!updated) {
            requestWorkDetails.push(payload);
         }
      } else {
         requestWorkDetails.push(payload);
      }
      patchState({ ...state, requestWorkDetails: requestWorkDetails });
   }

   @Receiver()
   public static setSiteAttachment({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<SiteAttachmentItem>) {
      const state = getState();
      let siteAttachments: SiteAttachmentItem[] = [];
      if (state.siteAttachments && state.siteAttachments.length > 0) {
         siteAttachments = JSON.parse(JSON.stringify(state.siteAttachments));
      }
      if (siteAttachments && siteAttachments.length > 0) {
         let updated = false;
         for (const counter in siteAttachments) {
            if (siteAttachments[counter].requestId === payload.requestId) {
               updated = true;
               siteAttachments[counter].requestId = payload.requestId;
               siteAttachments[counter].attachments = payload.attachments;
               break;
            }
         }
         if (!updated) {
            siteAttachments.push(payload);
         }
      } else {
         siteAttachments.push(payload);
      }
      patchState({ ...state, siteAttachments: siteAttachments });
   }

   @Receiver()
   public static deleteAttachment({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<DeleteRequestAttachment>) {
      const state = getState();
      let siteAttachments: SiteAttachmentItem[] = [];
      let syncJobs: any[] = [];
      if (state.siteAttachments) {
         siteAttachments = JSON.parse(JSON.stringify(state.siteAttachments));
      }
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      if (siteAttachments && siteAttachments.length > 0) {
         for (const counter in siteAttachments) {
            if (siteAttachments[counter].requestId === payload.requestId) {
               let currentAttachments: any[] = siteAttachments[counter].attachments;
               [currentAttachments, syncJobs] = deleteAttachment(currentAttachments, payload, syncJobs);
               siteAttachments[counter].attachments = currentAttachments;
            }
         }
      }
      patchState({ ...state, siteAttachments: siteAttachments, syncJobs: syncJobs });
   }

   @Receiver()
   public static updateRequest({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<Requests>) {
      const state = getState();
      let allRequests: Requests[] = [];
      let syncJobs: any[] = [];
      let syncJob: any = null;
      if (state.allrequests) {
         allRequests = JSON.parse(JSON.stringify(state.allrequests));
      }
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      if (allRequests && allRequests.length > 0) {
         [allRequests, syncJob] = updateRequestById(allRequests, payload);
         syncJobs = pushToSyncJobs(syncJobs, syncJob);
         patchState({ ...state, allrequests: allRequests, syncJobs: syncJobs });
      }
   }

   @Receiver()
   public static updateWorkDetail({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<WorkDetail>) {
      const state = getState();
      let workDetails: RequestWorkDetail[] = [];
      let syncJobs: any[] = [];
      if (state.requestWorkDetails) {
         workDetails = JSON.parse(JSON.stringify(state.requestWorkDetails));
      }
      if (state.syncJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncJobs));
      }
      if (workDetails && workDetails.length > 0) {
         for (const counter in workDetails) {
            if (workDetails[counter].requestId === payload.requestId) {
               workDetails[counter].workdetail = payload;
               syncJobs.push({
                  workDetail: payload,
                  syncType: FullfillmentSyncTypes.UpdateWorkDetail,
               });
            }
         }
         patchState({ ...state, requestWorkDetails: workDetails, syncJobs: syncJobs });
      }
   }

   @Receiver()
   public static processTopSyncJob({ patchState, getState }: StateContext<FullfillmentStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      const syncDueJobs = JSON.parse(JSON.stringify(state.syncJobs));
      syncDueJobs.shift();
      patchState({ ...state, syncJobs: syncDueJobs });
   }

   @Selector()
   static getAllRequests(state: FullfillmentStateModel): Requests[] {
      if (state && state.allrequests) {
         return state.allrequests;
      } else {
         return [];
      }
   }

   @Selector()
   static getSiteOperatives(state: FullfillmentStateModel): User[] {
      if (state && state.siteOperatives) {
         return state.siteOperatives;
      } else {
         return [];
      }
   }

   @Selector()
   static getTequestsByStatus(state: FullfillmentStateModel): Function {
      return (status: RequestStatus): Requests[] => {
         if (state && state.allrequests) {
            const requestItems: Requests[] = JSON.parse(JSON.stringify(state.allrequests));
            const filtered: Requests[] = requestItems.filter((item: Requests) => item.status === status);
            return filtered;
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getAttachmentByRequestId(state: FullfillmentStateModel): Function {
      return (requestId: number): File[] => {
         if (state && state.siteAttachments) {
            const siteAttachments: SiteAttachmentItem[] = JSON.parse(JSON.stringify(state.siteAttachments));
            const filtered: SiteAttachmentItem[] = siteAttachments.filter((item: SiteAttachmentItem) => item.requestId === requestId);
            if (filtered.length > 0) {
               return filtered[0].attachments;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getWorkdetaiByRequestId(state: FullfillmentStateModel): Function {
      return (requestId: number): WorkDetail => {
         if (state && state.requestWorkDetails) {
            const requestWorkDetail: RequestWorkDetail[] = JSON.parse(JSON.stringify(state.requestWorkDetails));
            const filtered: RequestWorkDetail[] = requestWorkDetail.filter((item: RequestWorkDetail) => item.requestId === requestId);
            if (filtered.length > 0) {
               return filtered[0].workdetail;
            }
            return null;
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getAssetsByBuildingID(state: FullfillmentStateModel): Function {
      return (buildingId: number): Asset[] => {
         if (state && state.assetList) {
            const assetList: AssetListItem[] = JSON.parse(JSON.stringify(state.assetList));
            const filtered: AssetListItem[] = assetList.filter((item: AssetListItem) => item.buildingId === buildingId);
            if (filtered.length > 0) {
               return filtered[0].assets;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getSupplierUser(state: FullfillmentStateModel): Function {
      return (email: string): any => {
         if (state && state.supplierUsers) {
            const supplierUsers: SupplierUser[] = JSON.parse(JSON.stringify(state.supplierUsers));
            const filtered: SupplierUser[] = supplierUsers.filter((item: SupplierUser) => item.email === email);
            if (filtered.length > 0) {
               return filtered[0].user;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getPriorityItem(state: FullfillmentStateModel): Function {
      return (tenantId: number): any => {
         if (state && state.priorityItems) {
            const priorityItems: PriorityItem[] = JSON.parse(JSON.stringify(state.priorityItems));
            const filtered: PriorityItem[] = priorityItems.filter((item: PriorityItem) => item.tenantId === tenantId);
            if (filtered.length > 0) {
               return filtered[0].priority;
            }
            return null;
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getInspectItem(state: FullfillmentStateModel): Function {
      return (workDetailId: number): any => {
         if (state && state.inspectionList) {
            const inspectionList: InspectionListItem[] = JSON.parse(JSON.stringify(state.inspectionList));
            const filtered: InspectionListItem[] = inspectionList.filter((item: InspectionListItem) => item.workdetailId === workDetailId);
            if (filtered.length > 0) {
               return filtered[0].inspections;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getFileResultItem(state: FullfillmentStateModel): Function {
      return (workDetailId: number, type: EntityType): any => {
         if (state && state.fileResults) {
            const fileResults: FileResultItem[] = JSON.parse(JSON.stringify(state.fileResults));
            const filtered: FileResultItem[] = fileResults.filter((item: FileResultItem) => item.workdetailId === workDetailId && item.type === type);
            if (filtered.length > 0) {
               return filtered[0].fileResults;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getRequestMessages(state: FullfillmentStateModel): Function {
      return (requestId: number): any => {
         if (state && state.requestMessages) {
            const requestMessages: RequestMessagesItem[] = JSON.parse(JSON.stringify(state.requestMessages));
            const filtered: RequestMessagesItem[] = requestMessages.filter((item: RequestMessagesItem) => item.requestId === requestId);
            if (filtered.length > 0) {
               return filtered[0].messages;
            }
            return [];
         } else {
            return [];
         }
      };
   }

   @Selector()
   static getRequestById(state: FullfillmentStateModel): Function {
      return (id: number): Requests => {
         if (state && state.allrequests) {
            const requests: Requests[] = JSON.parse(JSON.stringify(state.allrequests));
            const filtered = requests.filter((req: Requests) => req.id === id);
            if (filtered.length > 0) {
               return filtered[0];
            }
         }
         return null;
      };
   }

   @Selector()
   static getSyncJobs(state: FullfillmentStateModel): any[] {
      return JSON.parse(JSON.stringify(state.syncJobs));
   }
}
