<nz-page-header (nzBack)="onBack()" nzBackIcon [nzTitle]="'User Settings'" class="pt-2"
   ><nz-page-header-extra>
      <button class="btn btn-blue Single-button mt-2" nzShape="round" nz-button (click)="openUserSettingsDetail()">
         <span nz-icon nzType="edit" nzTheme="outline"></span> Edit
      </button>
   </nz-page-header-extra>
</nz-page-header>
<div nz-row class="mb10">
   <!-- <hr style="height: 5px; width: 100%; border-width: 0; color: #044284; background-color: #044284; margin-bottom: 0%;" /> -->
   <div nz-col nzSpan="24" class="page-container">
      <nz-descriptions nzBordered [nzSize]="'small'">
         <nz-descriptions-item nzTitle="Profile Picture" [nzSpan]="2">
            <nz-upload
               class="avatar-uploader"
               nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
               nzName="avatar"
               nzListType="picture"
               [nzShowUploadList]="false"
               [nzBeforeUpload]="beforeUpload"
               (nzChange)="handleChange($event)"
            >
               <ng-container *ngIf="!avatarUrl">
                  <span class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></span>
                  <div class="ant-upload-text">Upload</div>
               </ng-container>
               <div *ngIf="avatarUrl" class="avatarImage" [ngStyle]="{ 'background-image': 'url(' + avatarUrl + ')' }"></div>
               <!-- <img *ngIf="avatarUrl" [src]="avatarUrl" style="width: 100%;" /> -->
            </nz-upload>
         </nz-descriptions-item>
         <nz-descriptions-item nzTitle="Full Name" [nzSpan]="2">{{ userProfile.firstName + " " + userProfile.lastName }}</nz-descriptions-item>
         <!-- <nz-descriptions-item nzTitle="First Name" [nzSpan]="2">{{ userProfile.firstName }}</nz-descriptions-item>
         <nz-descriptions-item nzTitle="Surname" [nzSpan]="2">{{ userProfile.lastName }}</nz-descriptions-item> -->
         <nz-descriptions-item nzTitle="Email" [nzSpan]="2">{{ userProfile.email }}</nz-descriptions-item>
         <nz-descriptions-item nzTitle="Phone Number" [nzSpan]="2">{{ userProfile.phoneNumber }}</nz-descriptions-item>
         <nz-descriptions-item nzTitle="Designation" [nzSpan]="2">{{ userProfile.position }}</nz-descriptions-item>
         <nz-descriptions-item nzTitle="Password" [nzSpan]="2"
            ><a nz-button nzType="link" (click)="navigateToResetPassword()">Change Password</a></nz-descriptions-item
         >
         <nz-descriptions-item nzTitle="User Type">{{ userProfile.userTypeName }}</nz-descriptions-item>
         <nz-descriptions-item nzTitle="Modules">{{ modules }}</nz-descriptions-item>
      </nz-descriptions>
   </div>
</div>

<div>
   <nz-modal [(nzVisible)]="emailFormVisible" nzTitle="Enter Email Address" [nzFooter]="footer" nzOkText="Submit" nzClosable="false">
      <form nz-form [formGroup]="emailForm">
         <div nz-row>
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>Email Address</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="23">
                     <nz-form-control>
                        <input nz-input formControlName="email" placeholder="Email Address" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </form>
      <ng-template #footer>
         <button nz-button nzType="default" (click)="closeForm()">Cancel</button>
         <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
      </ng-template>
   </nz-modal>
</div>

<nz-drawer
   [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
   [nzMaskClosable]="false"
   [nzWidth]="480"
   [nzVisible]="showUserSettingDrawer"
   nzTitle="User Settings"
   (nzOnClose)="closeUserSettingDrawer()"
>
   <form nz-form [formGroup]="validateForm" [nzLayout]="'vertical'" (ngSubmit)="updateUserProfileDetail()">
      <div nz-row>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>First Name</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="23">
                     <nz-form-control>
                        <input nz-input formControlName="firstName" placeholder="First Name" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>Surname</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="24">
                     <nz-form-control>
                        <input nz-input formControlName="surname" placeholder="Surname" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </div>
      <div nz-row>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>Email</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="23">
                     <nz-form-control>
                        <input nz-input formControlName="email" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label>Phone Number</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="24">
                     <nz-form-control>
                        <input nz-input formControlName="phoneNumber" placeholder="Phone Number" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </div>
      <div nz-row>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>Designation</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="23">
                     <nz-form-control>
                        <input nz-input formControlName="designation" placeholder="Designation" />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </div>
      <div nz-row>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>User Type</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="23">
                     <nz-form-control>
                        <input nz-input formControlName="userType" placeholder="User Type" readonly />
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </div>
      <div nz-row>
         <div nz-col [nzSpan]="24">
            <nz-form-item>
               <div nz-row>
                  <div nz-col>
                     <nz-form-label nzRequired>Modules</nz-form-label>
                  </div>
               </div>
               <div nz-row>
                  <div nz-col [nzSpan]="24">
                     <nz-form-control>
                        <textarea formControlName="modules" nz-input rows="5" readonly></textarea>
                     </nz-form-control>
                  </div>
               </div>
            </nz-form-item>
         </div>
      </div>
      <nz-form-item>
         <nz-form-control [nzSpan]="24">
            <div nz-row [nzGutter]="16">
               <div nz-col [nzSpan]="12">
                  <button
                     nz-button
                     nzBlock
                     type="reset"
                     class="btn btn-outline-gray Single-button mt-2"
                     nzShape="round"
                     nzType="default"
                     (click)="closeUserSettingDrawer()"
                  >
                     Close
                  </button>
               </div>
               <div nz-col [nzSpan]="12">
                  <button
                     nz-button
                     nzBlock
                     type="submit"
                     class="btn btn-blue Single-button mt-2"
                     nzShape="round"
                     [disabled]="validateForm.invalid"
                     [nzLoading]="isSaving"
                  >
                     Save
                  </button>
               </div>
            </div>
         </nz-form-control>
      </nz-form-item>
   </form>
</nz-drawer>
