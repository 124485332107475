<!-- <p>{{ message | async }}</p> -->

<div  *ngIf="isSuccessed; else isFailed">
   <nz-result
      nzStatus="success"
      nzTitle="FM Navigate"
      nzSubTitle="Please wait, we are getting you to the Login"
   >
   </nz-result>
   <ng-template #indicatorTemplate
      ><em nz-icon nzType="lock" style="font-size: 24px;"></em>
   </ng-template>
   <div style="text-align: center;">
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
   </div>
   <div *ngIf="isIdle">
      <button
         nzType="warning"
         nz-popconfirm
         nzPopconfirmTitle="Session expired. You will be redirected to login page"
         (nzOnConfirm)="sessionExpireOk()"
         (nzOnCancel)="cancel()"
         nzPopconfirmPlacement="topLeft"
         nz-button
      >
            OK
      </button>
   </div>
</div>
<ng-template #isFailed>
   <nz-result nzStatus="warning" nzTitle="FM Navigate" nzSubTitle="Oops...! Your login request failed!">
      <div nz-result-extra>
         <div class="flex justify-content-center">
            <button nz-button nzType="primary" class="btn btn-blue Single-button" nzShape="round" (click)="logout()">Login Again</button>
         </div>
         <div class="flex justify-content-center mt-2">
            <button nz-button nzType="text" style="color: #006FFF; font-weight: 500;" (click)="loginFailEmail()">Complain the login isuue to admin.</button>
         </div>
      </div>
    </nz-result>
</ng-template>


