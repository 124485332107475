export enum CompanyCategory {
   Supplier = 0,
   PublicBuyer = 10,
   PrivateBuyer = 20,
   Auditor = 30,
   Consultant = 40,
}

export enum TenantType {
   Buyer = 10,
   Supplier = 20,
   Auditor = 30,
   Consultant = 40,
}

export enum RegistrationStatus {
   Invited = 10,
   Inprogress = 15,
   Completed = 20,
   Approved = 30,
   Rejected = 40,
}

export enum UserType {
   Admin = 10,
   Buyer = 20,
   Supplier = 30,
   SupplierOperative = 31,
   Auditor = 40,
   FulfillmentCustomer = 50,
   Consultant = 60,
}

export enum RequirementCategory {
   Group = 10,
   SubGroup = 20,
   Item = 30,
}

export enum RequirementType {
   None = 10,
   Document = 20,
   Narrative = 30,
   Sample = 40,
   Evidence = 50,
}

export enum RequirementPriority {
   Low = 10,
   Medium = 20,
   High = 30,
}

export enum RequirementStatus {
   New = 10,
   Inprogress = 20,
   InReview = 30,
   ForApproval = 40,
   Approved = 50,
   Accepted = 60,
   Rejected = 70,
}

export enum RoleType {
   Owner = 10,
   Assignee = 20,
   Reviewer = 30,
   Approver = 40,
   Auditor = 50,
   Consultant = 60,
}

export enum RequirementVisibility {
   Public = 10,
   Private = 20,
}

export enum RequirementClassification {
   Plan = 10,
   Do = 20,
   Check = 30,
   Act = 40,
   Other = 50,
}

export enum AuditorStatus {
   NotSet = 0,
   Conform = 10,
   MinorNonConformance = 20,
   MajorNonConformance = 30,
   OpportunityForImprovement = 40,
   Observation = 50,
}

export enum SystemNotificationStatus {
   New = 10,
   Read = 20,
}

export enum SectionType {
   TableSection = 10,
   GridSection = 20,
}