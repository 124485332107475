import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './_shared/shared.module';
import { AppNgZorroAntdModule } from './_shared/nz/nz.module';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { SupplierState } from './compliance/_state/supplier.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FullfillementState } from './fulfillment/_state/fullfillment.state';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Idle, NgIdleModule } from '@ng-idle/core';
// import { AppLayoutModule } from './kpi/layout/app.layout.module';
// import { AppLayoutComponent } from './kpi/layout/app.layout.component';

@NgModule({
   declarations: [AppComponent, HomeComponent],
   imports: [
      AdminModule,
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      HttpClientModule,
      FormsModule,
      AppNgZorroAntdModule,
      BrowserAnimationsModule,
      NgIdleModule.forRoot(),
      NgIdleKeepaliveModule.forRoot(),
      ApiAuthorizationModule,
      SharedModule,
      RouterModule.forRoot(
         [
            {
               path: '',
               component: HomeComponent,
               pathMatch: 'full',
               canActivate: [AuthorizeGuard],
            },
            {
               path: 'admin',
               loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
            },
            {
               path: 'tender',
               loadChildren: () => import('./tender/tender.module').then((m) => m.TenderModule),
            },
            {
               path: 'compliance',
               loadChildren: () => import('./compliance/compliance.module').then((m) => m.ComplianceModule),
            },
            {
               path: 'certification',
               loadChildren: () => import('./certification/certification.module').then((m) => m.CertificationModule),
            },
            {
               path: 'fulfillment',
               loadChildren: () => import('./fulfillment/fulfillment.module').then((m) => m.FulfillmentModule),
            },
            {
               path: 'kpi',
               loadChildren: () => import('./kpi/kpi.module').then((m) => m.KpiModule),
            },
            {
               path: 'contract',
               loadChildren: () => import('./contract/contract.module').then((m) => m.ContractModule),
            },
         ],
         { relativeLinkResolution: 'legacy' }
      ),
      ServiceWorkerModule.register('ngsw-worker.js', {
         enabled: environment.production,
         // Register the ServiceWorker as soon as the app is stable
         // or after 30 seconds (whichever comes first).
         registrationStrategy: 'registerWhenStable:30000',
      }),
      NgxsModule.forRoot([SupplierState, FullfillementState], {
         developmentMode: !environment.production,
      }),
      NgxsResetPluginModule.forRoot(),
      NgxsEmitPluginModule.forRoot(),
      NgxsStoragePluginModule.forRoot(),
      NgxsFormPluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthorizeInterceptor,
         multi: true,
      },
      { provide: NZ_I18N, useValue: en_US },
      // { provide: APP_BASE_HREF, useValue: '/Identity/Account/Login?ReturnUrl=%2'},
      DatePipe,
      Idle,
      Keepalive,
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
