import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective {
  @Output() doubleClick = new EventEmitter<MouseEvent>();

  private clickCount = 0;
  private clickTimeout: any;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.clickCount++;

    if (this.clickCount === 1) {
      this.clickTimeout = setTimeout(() => {
        this.clickCount = 0;
      }, 300);
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimeout);
      this.clickCount = 0;
      this.doubleClick.emit(event);
    }
  }
}
