import { Component, EventEmitter, OnInit, OnChanges, OnDestroy, Input, Output } from '@angular/core';
import * as Leaflet from 'leaflet';

Leaflet.Icon.Default.imagePath = 'assets/leaflet/';

@Component({
   selector: 'app-geo-map',
   templateUrl: './geo-map.component.html',
   styleUrls: ['./geo-map.component.css'],
})
export class GeoMapComponent implements OnInit, OnChanges, OnDestroy {
   @Input() inputGeoLocation: any;
   @Output() outputGeoLocation: EventEmitter<any> = new EventEmitter<any>();
   longitude: number = -0.118092;
   latitude: number = 51.509865;

   map!: Leaflet.Map;
   markers: Leaflet.Marker[] = [];
   options = {
      layers: [
         Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
         }),
      ],
      zoom: 16,
      center: { lat: 51.509865, lng: -0.118092 },
   };

   constructor() {}

   ngOnInit(): void {}

   ngOnChanges(): void {
      this.addMark(this.inputGeoLocation.lat, this.inputGeoLocation.lng);
      this.longitude = this.inputGeoLocation.lng;
      this.latitude = this.inputGeoLocation.lat;
      this.moveToLocation(this.inputGeoLocation.lat, this.inputGeoLocation.lng);
   }

   ngOnDestroy(): void {
      this.longitude = -0.118092;
      this.latitude = 51.509865;
   }

   generateMarker(data: any, index: number) {
      return Leaflet.marker(data.position, { draggable: data.draggable })
         .on('click', (event) => this.markerClicked(event, index))
         .on('dragend', (event) => this.markerDragEnd(event, index));
   }

   onMapReady($event: Leaflet.Map) {
      this.map = $event;
      // this.initMarkers();
   }

   mapClicked($event: any) {
      if ($event.latlng.lat >= -90 && $event.latlng.lat <= 90) {
         this.latitude = $event.latlng.lat;
      } else if ($event.latlng.lat < -90) {
         this.latitude = -90;
      } else if ($event.latlng.lat > 90) {
         this.latitude = 90;
      }

      if ($event.latlng.lng >= -180 && $event.latlng.lng <= 180) {
         this.longitude = $event.latlng.lng;
      } else if ($event.latlng.lng < -180) {
         this.longitude = -180;
      } else if ($event.latlng.lng > 180) {
         this.longitude = 180;
      }

      const dt = `{
      "lng": ${this.longitude},
      "lat": ${this.latitude}
    }`;
      this.outputGeoLocation.emit(JSON.parse(dt));

      this.addMark(this.latitude, this.longitude);
   }

   addMark(lat: number, lng: number) {
      const data = {
         position: { lat: lat, lng: lng },
         draggable: true,
      };

      if (this.markers[0]) {
         this.map.removeLayer(this.markers[0]);
         this.markers.pop();
      }

      const marker = this.generateMarker(data, 0);
      marker.addTo(this.map).bindPopup(`<b>${data.position.lat},  ${data.position.lng}</b>`);
      this.markers.push(marker);
   }

   markerClicked($event: any, index: number) {
      // 
   }

   markerDragEnd($event: any, index: number) {
      // 
   }

   moveToLocation(lat: number, lng: number) {
      this.map.setView([lat, lng], 16);
   }
}
