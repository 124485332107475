import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FileResult } from "../_entities/FileResult";
import { ApplicationTenant } from "../_entities/ApplicationTenant";
import { PasswordResetModel } from "../_entities/PasswordResetModel";

@Injectable({
    providedIn: 'root',
 })
 export class UserSettingService {
    private baseUrl: string = window.location.origin + '/api/admin/usersetting';
 
    constructor(private http: HttpClient) {}

    uploadUserProfilePic(tenantId: number, userId: number, file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('fileListForm', file as any);
        return this.http.post(`${this.baseUrl}/${tenantId}/${userId}/uploaduserprofilepic`, formData);
    }

    getuserProfilePic(tenantId: number, userId: number): Observable<FileResult> {
        return this.http.get<FileResult>(`${this.baseUrl}/${tenantId}/${userId}/getuserprofile`);
    }

    getApplications(tenantId: number): Observable<ApplicationTenant[]> {
        return this.http.get<ApplicationTenant[]>(`${this.baseUrl}/${tenantId}/getmodules`);
    }

    resetPassword(model: PasswordResetModel): Observable<any> {
        return this.http.post(window.location.origin + '/api/admin/user/resetPassword', model);
    }
 }
 