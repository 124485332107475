<nz-modal
         [(nzVisible)]="visible"
         nzTitle="Session Expired"
         nzOkText="OK"
         (nzOnOk)="accept()"
      >
         <p>
           {{confirmation?.message}}
         </p>
      </nz-modal>
