import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { ApplicationUser } from 'src/app/admin/_entities/ApplicationUser';
import { EntityType } from 'src/app/admin/_entities/Enums';
import { AssetScheduleFileResult } from '../_entities/AssetScheduleFileResult';
import { AssetScheduleOccurence } from '../_entities/AssetScheduleOccurrence';
import { SupplierScheduleResult } from '../_entities/AssetScheduleResult';
import { BuildingScheduleFileResult } from '../_entities/BuildingScheduleFileResult';
import { BuildingScheduleOccurence } from '../_entities/BuildingScheduleOccurrence';
import { ScheduleOccurrenceStatus, SyncTypes } from '../_entities/Enums';
import { BaseFormData } from '../_entities/Form';
import { SiteScheduleFileResult } from '../_entities/SiteScheduleFileResult';
import { SiteScheduleOccurence } from '../_entities/SiteScheduleOccurrence';

const updateBuildingScheduleOccurance = (buildingScheduleOccurances, payload) => {
   let updated = false;
   for (const counter in buildingScheduleOccurances) {
      if (buildingScheduleOccurances[counter].scheduleId === payload.scheduleId) {
         updated = true;
         buildingScheduleOccurances[counter].scheduleId = payload.scheduleId;
         buildingScheduleOccurances[counter].result = payload.result;
         break;
      }
   }
   return [buildingScheduleOccurances, updated];
};

const manageMessages = (ocmessages: any[], payload: any) => {
   if (ocmessages && ocmessages.length > 0) {
      let updated = false;
      for (const counter in ocmessages) {
         if (ocmessages[counter].scheduleOccuranceId === payload.scheduleOccuranceId) {
            updated = true;
            ocmessages[counter].message.push(payload.message);
            break;
         }
      }
      if (!updated) {
         ocmessages.push({
            scheduleOccuranceId: payload.scheduleOccuranceId,
            message: [payload.message],
         });
      }
   }
   return ocmessages;
};

class BuildingScheduleOccuranceFormModel extends BaseFormData {
   model?: any;
}

export class BuildingScheduleItem {
   buildingId: number;
   result: BuildingScheduleFileResult;
}

export class BuildingScheduleOccuranceItem {
   scheduleId: number;
   result: BuildingScheduleOccurence[];
}

export class SiteScheduleOccuranceItem {
   scheduleId: number;
   result: SiteScheduleOccurence[];
}

export interface BuildingScheduleMessageItem {
   scheduleOccuranceId: number;
   message: any;
}

export interface SiteScheduleMessageItem {
   scheduleOccuranceId: number;
   message: any;
}

export interface AssetScheduleMessageItem {
   scheduleOccuranceId: number;
   message: any;
}

export interface ActivityprocedureItem {
   scheduleId: number;
   entity: EntityType;
   file: any;
}

export interface AssetScheduleFileResultItem {
   buildingId: number;
   fileResults: AssetScheduleFileResult;
}

export interface AssetScheduleOccurenceItem {
   scheduleId: number;
   result: AssetScheduleOccurence[];
}

export interface SupplierStateModel {
   buildingScheduleOccuranceFormModel?: BuildingScheduleOccuranceFormModel;
   buildingScheduleOccurence?: BuildingScheduleOccurence;
   assetScheduleOccurances?: AssetScheduleOccurenceItem[];
   assetScheduleList?: SupplierScheduleResult;
   siteScheduleList?: SiteScheduleFileResult;
   buildingSchedules?: BuildingScheduleItem[];
   buildingScheduleOccurances?: BuildingScheduleOccuranceItem[];
   siteScheduleOccurances?: SiteScheduleOccuranceItem[];
   syncDueJobs?: any[];
   buildingScheduleMessages?: BuildingScheduleMessageItem[];
   siteScheduleMessages?: SiteScheduleMessageItem[];
   assetScheduleMessages?: AssetScheduleMessageItem[];
   userApplications?: ApplicationUser[];
   activityProcedures?: ActivityprocedureItem[];
   assetScheduleFileResults?: AssetScheduleFileResultItem[];
}

export interface BuildingScheduleUpdatePayload {
   scheduleId: number;
   buildingScheduleId: number;
   schedule: BuildingScheduleOccurence;
}
export interface SiteScheduleUpdatePayload {
   scheduleId: number;
   siteScheduleId: number;
   schedule: SiteScheduleOccurence;
}

@State<SupplierStateModel>({
   name: 'supplier',
   defaults: {
      buildingScheduleOccuranceFormModel: { model: null },
      buildingScheduleOccurence: null,
      assetScheduleOccurances: [],
      assetScheduleList: null,
      siteScheduleList: null,
      buildingSchedules: [],
      buildingScheduleOccurances: [],
      siteScheduleOccurances: [],
      syncDueJobs: [],
      buildingScheduleMessages: [],
      userApplications: [],
      assetScheduleFileResults: [],
      assetScheduleMessages: [],
      siteScheduleMessages: []
   },
})
@Injectable()
export class SupplierState {
   @Receiver()
   public static setBuildingScheduleOccuranceForm({ patchState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      if (payload) {
         patchState({
            buildingScheduleOccuranceFormModel: {
               model: payload,
            },
         });
      } else {
         patchState({
            buildingScheduleOccuranceFormModel: { model: null },
         });
      }
   }

   @Receiver()
   public static setUserApplications({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      patchState({ ...state, userApplications: payload });
   }

   @Receiver()
   public static setBuildingSchedules({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let buildingSchedules = [...state.buildingSchedules];
      if (buildingSchedules && buildingSchedules.length > 0) {
         let updated = false;
         for (const counter in buildingSchedules) {
            if (buildingSchedules[counter].buildingId === payload.buildingId) {
               updated = true;
               buildingSchedules[counter].buildingId = payload.buildingId;
               buildingSchedules[counter].result = payload.result;
               break;
            }
         }
         if (!updated) {
            buildingSchedules.push(payload);
         }
      } else {
         buildingSchedules = [payload];
      }
      patchState({ ...state, buildingSchedules: buildingSchedules });
   }

   @Receiver()
   public static setAssetScheduleFileResult({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let assetScheduleFileResults: AssetScheduleFileResultItem[] = [];
      if (state.assetScheduleFileResults) {
         assetScheduleFileResults = JSON.parse(JSON.stringify(state.assetScheduleFileResults));
      }
      if (assetScheduleFileResults && assetScheduleFileResults.length > 0) {
         let updated = false;
         for (const counter in assetScheduleFileResults) {
            if (assetScheduleFileResults[counter].buildingId === payload.buildingId) {
               updated = true;
               assetScheduleFileResults[counter].buildingId = payload.buildingId;
               assetScheduleFileResults[counter].fileResults = payload.fileResults;
               break;
            }
         }
         if (!updated) {
            assetScheduleFileResults.push(payload);
         }
      } else {
         assetScheduleFileResults = [payload];
      }
      patchState({ ...state, assetScheduleFileResults: assetScheduleFileResults });
   }

   @Receiver()
   public static setActivityProcedure(
      { patchState, getState }: StateContext<SupplierStateModel>,
      { payload }: EmitterAction<ActivityprocedureItem>
   ) {
      const state = getState();
      let activityProcedures: ActivityprocedureItem[] = [];
      if (state.activityProcedures) {
         activityProcedures = JSON.parse(JSON.stringify(state.activityProcedures));
      }
      if (activityProcedures && activityProcedures.length > 0) {
         let updated = false;
         for (const counter in activityProcedures) {
            if (activityProcedures[counter].scheduleId === payload.scheduleId && activityProcedures[counter].entity === payload.entity) {
               updated = true;
               activityProcedures[counter].file = payload.file;
               break;
            }
         }
         if (!updated) {
            activityProcedures.push(payload);
         }
      } else {
         activityProcedures = [payload];
      }
      patchState({ ...state, activityProcedures: activityProcedures });
   }

   @Receiver()
   public static setBuildingScheduleMessage(
      { patchState, getState }: StateContext<SupplierStateModel>,
      { payload }: EmitterAction<BuildingScheduleMessageItem>
   ) {
      const state = getState();
      let messages: any[] = [];
      if (state.buildingScheduleMessages !== undefined) {
         messages = JSON.parse(JSON.stringify(state.buildingScheduleMessages));
      }
      if (messages && messages.length > 0) {
         let updated = false;
         for (const counter in messages) {
            if (messages[counter].scheduleOccuranceId === payload.scheduleOccuranceId) {
               updated = true;
               messages[counter].message = payload.message;
               break;
            }
         }
         if (!updated) {
            messages.push(payload);
         }
      } else {
         messages = [payload];
      }
      patchState({ ...state, buildingScheduleMessages: messages });
   }

   @Receiver()
   public static setSiteScheduleMessage({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<SiteScheduleMessageItem>) {
      const state = getState();
      let messages: any[] = [];
      if (state.siteScheduleMessages !== undefined) {
         messages = JSON.parse(JSON.stringify(state.siteScheduleMessages));
      }
      if (messages && messages.length > 0) {
         let updated = false;
         for (const counter in messages) {
            if (messages[counter].scheduleOccuranceId === payload.scheduleOccuranceId) {
               updated = true;
               messages[counter].message = payload.message;
               break;
            }
         }
         if (!updated) {
            messages.push(payload);
         }
      } else {
         messages = [payload];
      }
      patchState({ ...state, siteScheduleMessages: messages });
   }

   @Receiver()
   public static setAssetScheduleMessage({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<AssetScheduleMessageItem>) {
      const state = getState();
      let messages: any[] = [];
      if (state.assetScheduleMessages !== undefined) {
         messages = JSON.parse(JSON.stringify(state.assetScheduleMessages));
      }
      if (messages && messages.length > 0) {
         let updated = false;
         for (const counter in messages) {
            if (messages[counter].scheduleOccuranceId === payload.scheduleOccuranceId) {
               updated = true;
               messages[counter].message = payload.message;
               break;
            }
         }
         if (!updated) {
            messages.push(payload);
         }
      } else {
         messages = [payload];
      }
      patchState({ ...state, assetScheduleMessages: messages });
   }

   @Receiver()
   public static setBuildingScheduleMessageItem(
      { patchState, getState }: StateContext<SupplierStateModel>,
      { payload }: EmitterAction<BuildingScheduleMessageItem>
   ) {
      const state = getState();
      let ocmessages: any[] = [];
      if (state.buildingScheduleMessages !== undefined) {
         ocmessages = JSON.parse(JSON.stringify(state.buildingScheduleMessages));
      }
      if (ocmessages && ocmessages.length > 0) {
         let updated = false;
         for (const counter in ocmessages) {
            if (ocmessages[counter].scheduleOccuranceId === payload.scheduleOccuranceId) {
               updated = true;
               ocmessages[counter].message.push(payload.message);
               break;
            }
         }
         if (!updated) {
            ocmessages.push({
               scheduleOccuranceId: payload.scheduleOccuranceId,
               message: [payload.message],
            });
         }
      }
      patchState({ ...state, buildingScheduleMessages: ocmessages });
   }

   @Receiver()
   public static setSyncDueJob({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let jobs = [];
      const buildingScheduleOccurances = JSON.parse(JSON.stringify(state.buildingScheduleOccurances));
      jobs = JSON.parse(JSON.stringify(state.syncDueJobs));
      let ocmessages: any[] = [];
      if (state.buildingScheduleMessages !== undefined && payload.type === SyncTypes.BuildingSendMessages) {
         ocmessages = JSON.parse(JSON.stringify(state.buildingScheduleMessages));
      }
      if (state.buildingScheduleMessages !== undefined && payload.type === SyncTypes.SiteSendMessages) {
         ocmessages = JSON.parse(JSON.stringify(state.siteScheduleMessages));
      }
      if (state.assetScheduleMessages !== undefined && payload.type === SyncTypes.AssetSendMessage) {
         ocmessages = JSON.parse(JSON.stringify(state.assetScheduleMessages));
      }
      jobs.push(payload);
      if (payload.type === SyncTypes.BuildingSendMessages || payload.type === SyncTypes.SiteSendMessages || payload.type === SyncTypes.AssetSendMessage) {
         ocmessages = manageMessages(ocmessages, payload);
      }
      if (payload.type === SyncTypes.BuildingSendMessages) {
         patchState({ ...state, syncDueJobs: jobs, buildingScheduleOccurances: buildingScheduleOccurances, buildingScheduleMessages: ocmessages });
      } else if (payload.type === SyncTypes.SiteSendMessages) {
         patchState({ ...state, syncDueJobs: jobs, buildingScheduleOccurances: buildingScheduleOccurances, siteScheduleMessages: ocmessages });
      } else if (payload.type === SyncTypes.AssetSendMessage) {
         patchState({ ...state, syncDueJobs: jobs, buildingScheduleOccurances: buildingScheduleOccurances, assetScheduleMessages: ocmessages });
      } else {
         patchState({ ...state, syncDueJobs: jobs, buildingScheduleOccurances: buildingScheduleOccurances });
      }
   }

   @Receiver()
   public static updateOccuranceStatus({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      const buildingScheduleOccurances = JSON.parse(JSON.stringify(state.buildingScheduleOccurances));
      for (const scheduleCount in buildingScheduleOccurances) {
         if (buildingScheduleOccurances[scheduleCount].result) {
            for (const occuranceCount in buildingScheduleOccurances[scheduleCount].result) {
               if (buildingScheduleOccurances[scheduleCount].result[occuranceCount].id === Number(payload.scheduleOccuranceId)) {
                  buildingScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatus = payload.status;
                  buildingScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatusName = ScheduleOccurrenceStatus[payload.status];
               }
            }
         }
      }
      patchState({ ...state, buildingScheduleOccurances: buildingScheduleOccurances });
   }

   @Receiver()
   public static updateSiteOccuranceStatus({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let siteScheduleOccurances = [];
      let syncJobs = [];
      if (state.siteScheduleOccurances) {
         siteScheduleOccurances = JSON.parse(JSON.stringify(state.siteScheduleOccurances));
      }
      if (state.syncDueJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncDueJobs));
      }
      for (const scheduleCount in siteScheduleOccurances) {
         if (siteScheduleOccurances[scheduleCount].result) {
            for (const occuranceCount in siteScheduleOccurances[scheduleCount].result) {
               if (siteScheduleOccurances[scheduleCount].result[occuranceCount].id === Number(payload.scheduleOccuranceId)) {
                  siteScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatus = payload.status;
                  siteScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatusName = ScheduleOccurrenceStatus[payload.status];
                  syncJobs.push({
                     type: SyncTypes.StartSiteJob,
                     scheduleOccuranceId: payload.scheduleOccuranceId,
                     category: payload.category
                  });
               }
            }
         }
      }
      patchState({ ...state, siteScheduleOccurances: siteScheduleOccurances, syncDueJobs: syncJobs });
   }

   @Receiver()
   public static updateAssetOccuranceStatus({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let assetScheduleOccurances = [];
      let syncJobs = [];
      if (state.siteScheduleOccurances) {
         assetScheduleOccurances = JSON.parse(JSON.stringify(state.assetScheduleOccurances));
      }
      if (state.syncDueJobs) {
         syncJobs = JSON.parse(JSON.stringify(state.syncDueJobs));
      }
      for (const scheduleCount in assetScheduleOccurances) {
         if (assetScheduleOccurances[scheduleCount].result) {
            for (const occuranceCount in assetScheduleOccurances[scheduleCount].result) {
               if (assetScheduleOccurances[scheduleCount].result[occuranceCount].id === Number(payload.scheduleOccuranceId)) {
                  assetScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatus = payload.status;
                  assetScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatusName = ScheduleOccurrenceStatus[payload.status];
                  syncJobs.push({
                     type: SyncTypes.startAssetJob,
                     scheduleOccuranceId: payload.scheduleOccuranceId,
                     category: payload.category
                  });
               }
            }
         }
      }
      patchState({ ...state, assetScheduleOccurances: assetScheduleOccurances, syncDueJobs: syncJobs });
   }

   @Receiver()
   public static updateSiteOccuranceStatusComplete({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let siteScheduleOccurances = [];
      if (state.siteScheduleOccurances) {
         siteScheduleOccurances = JSON.parse(JSON.stringify(state.siteScheduleOccurances));
      }
      for (const scheduleCount in siteScheduleOccurances) {
         if (siteScheduleOccurances[scheduleCount].result) {
            for (const occuranceCount in siteScheduleOccurances[scheduleCount].result) {
               if (siteScheduleOccurances[scheduleCount].result[occuranceCount].id === Number(payload.scheduleOccuranceId)) {
                  siteScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatus = payload.status;
                  siteScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatusName = ScheduleOccurrenceStatus[payload.status];
               }
            }
         }
      }
      patchState({ ...state, siteScheduleOccurances: siteScheduleOccurances});
   }

   @Receiver()
   public static updateAssetOccuranceStatusComplete({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let assetScheduleOccurances = [];
      if (state.assetScheduleOccurances) {
         assetScheduleOccurances = JSON.parse(JSON.stringify(state.assetScheduleOccurances));
      }
      for (const scheduleCount in assetScheduleOccurances) {
         if (assetScheduleOccurances[scheduleCount].result) {
            for (const occuranceCount in assetScheduleOccurances[scheduleCount].result) {
               if (assetScheduleOccurances[scheduleCount].result[occuranceCount].id === Number(payload.scheduleOccuranceId)) {
                  assetScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatus = payload.status;
                  assetScheduleOccurances[scheduleCount].result[occuranceCount].scheduleOccurrenceStatusName = ScheduleOccurrenceStatus[payload.status];
               }
            }
         }
      }
      patchState({ ...state, assetScheduleOccurances: assetScheduleOccurances});
   }

   @Receiver()
   public static removeJobSyncDueJob({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let jobs = [];
      if (state.syncDueJobs !== undefined) {
         jobs = JSON.parse(JSON.stringify(state.syncDueJobs));
      }
      jobs.splice(payload.index, 1);
      patchState({ ...state, syncDueJobs: jobs });
   }

   @Receiver()
   public static emptySyncDueJobs({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      patchState({ ...state, syncDueJobs: [] });
   }

   @Receiver()
   public static processTopSyncJob({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      const syncDueJobs = JSON.parse(JSON.stringify(state.syncDueJobs));
      syncDueJobs.shift();
      patchState({ ...state, syncDueJobs: syncDueJobs });
   }

   @Receiver()
   public static setBuildingScheduleOccurances({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let buildingScheduleOccurances = [...state.buildingScheduleOccurances];
      if (payload.scheduleId && payload.scheduleId > 0) {
         if (buildingScheduleOccurances && buildingScheduleOccurances.length > 0) {
            let updated = false;
            [buildingScheduleOccurances, updated] = updateBuildingScheduleOccurance(buildingScheduleOccurances, payload);
            if (!updated) {
               buildingScheduleOccurances.push(payload);
            }
         } else {
            buildingScheduleOccurances = [payload];
         }
         patchState({ ...state, buildingScheduleOccurances: buildingScheduleOccurances });
      }
   }

   @Receiver()
   public static setAssetduleOccurances({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let assetScheduleOccurances = [...state.assetScheduleOccurances];
      if (payload.scheduleId && payload.scheduleId > 0) {
         if (assetScheduleOccurances && assetScheduleOccurances.length > 0) {
            let updated = false;
            [assetScheduleOccurances, updated] = updateBuildingScheduleOccurance(assetScheduleOccurances, payload);
            if (!updated) {
               assetScheduleOccurances.push(payload);
            }
         } else {
            assetScheduleOccurances = [payload];
         }
         patchState({ ...state, assetScheduleOccurances: assetScheduleOccurances });
      }
   }

   @Receiver()
   public static setSiteScheduleOccurances({ patchState, getState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      const state = getState();
      let siteScheduleOccurances = [];
      if (state.siteScheduleOccurances) {
         siteScheduleOccurances = JSON.parse(JSON.stringify(state.siteScheduleOccurances));
      }
      if (payload.scheduleId && payload.scheduleId > 0) {
         if (siteScheduleOccurances && siteScheduleOccurances.length > 0) {
            let updated = false;
            [siteScheduleOccurances, updated] = updateBuildingScheduleOccurance(siteScheduleOccurances, payload);
            if (!updated) {
               siteScheduleOccurances.push(payload);
            }
         } else {
            siteScheduleOccurances = [payload];
         }
         patchState({ ...state, siteScheduleOccurances: siteScheduleOccurances });
      }
   }

   @Receiver()
   public static updateBuildingScheduleOccurance(
      { patchState, getState }: StateContext<SupplierStateModel>,
      { payload }: EmitterAction<BuildingScheduleUpdatePayload>
   ) {
      const state = getState();
      const buildingScheduleOccurances = [...state.buildingScheduleOccurances];
      for (const counter in buildingScheduleOccurances) {
         if (buildingScheduleOccurances[counter].scheduleId === payload.scheduleId) {
            for (const increment in buildingScheduleOccurances[counter].result) {
               if (buildingScheduleOccurances[counter].result[increment].buildingScheduleId === Number(payload.buildingScheduleId)) {
                  for (const key of Object.keys(payload.schedule)) {
                     buildingScheduleOccurances[counter].result[increment][key] = payload.schedule[key];
                  }
               }
            }
            patchState({ ...state, buildingScheduleOccurances: buildingScheduleOccurances });
         }
      }
   }

   @Receiver()
   public static setAssetScheduleList({ patchState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      if (payload) {
         patchState({
            assetScheduleList: payload,
         });
      } else {
         patchState({
            assetScheduleList: null,
         });
      }
   }

   @Receiver()
   public static setSiteScheduleList({ patchState }: StateContext<SupplierStateModel>, { payload }: EmitterAction<any>) {
      patchState({
         siteScheduleList: payload,
      });
   }

   @Selector()
   static getBuildingScheduleOccuranceForm(state: SupplierStateModel): BuildingScheduleOccurence {
      return state.buildingScheduleOccuranceFormModel.model;
   }

   @Selector()
   static getAssetScheduleList(state: SupplierStateModel): SupplierScheduleResult {
      return state.assetScheduleList;
   }

   @Selector()
   static getSiteScheduleList(state: SupplierStateModel): SiteScheduleFileResult {
      return state.siteScheduleList;
   }

   @Selector()
   static getUserApplications(state: SupplierStateModel): ApplicationUser[] {
      return state.userApplications;
   }

   @Selector()
   static getSyncJobs(state: SupplierStateModel): any[] {
      return JSON.parse(JSON.stringify(state.syncDueJobs));
   }

   @Selector()
   static getBuildingSchedules(state: SupplierStateModel): Function {
      return (buildingId: number) => {
         const filtered = state.buildingSchedules.filter((buildingSchedule) => buildingSchedule.buildingId === buildingId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].result));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getAssetScheduleFileResultItem(state: SupplierStateModel): Function {
      return (buildingId: number) => {
         const filtered = state.assetScheduleFileResults.filter((assetScheduleFileResult) => assetScheduleFileResult.buildingId === buildingId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].fileResults));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getActivityProcedure(state: SupplierStateModel): Function {
      return (scheduleId: number, entity: EntityType) => {
         const filtered = state.activityProcedures.filter((activityProcedure) => {
            return (activityProcedure.scheduleId === scheduleId && activityProcedure.entity === entity);
         });
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].file));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getBuildingScheduleMessage(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number) => {
         const filtered = state.buildingScheduleMessages.filter((message) => message.scheduleOccuranceId === Number(scheduleOccuranceId));
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].message));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getSiteScheduleMessage(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number) => {
         const filtered = state.siteScheduleMessages.filter((message) => message.scheduleOccuranceId === Number(scheduleOccuranceId));
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].message));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getAssetScheduleMessage(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number) => {
         const filtered = state.assetScheduleMessages.filter((message) => message.scheduleOccuranceId === Number(scheduleOccuranceId));
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].message));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getBuildingScheduleOccurances(state: SupplierStateModel): Function {
      return (schedueId: number) => {
         const filtered = state.buildingScheduleOccurances.filter((buildingScheduleOccurance) => buildingScheduleOccurance.scheduleId === schedueId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].result));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getAssetScheduleOccurances(state: SupplierStateModel): Function {
      return (schedueId: number) => {
         const filtered = state.assetScheduleOccurances.filter((assetScheduleOccurance) => assetScheduleOccurance.scheduleId === schedueId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].result));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getAssetScheduleOccurance(state: SupplierStateModel): Function {
      return (occuranceId: number) => {
         const assetOccurances = [];
         for (const occuranceItem of state.assetScheduleOccurances) {
            for (const occurance of occuranceItem.result) {
               assetOccurances.push(occurance);
            }
         }
         const filtered = assetOccurances.filter((assetOccurance) => assetOccurance.id === occuranceId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0]));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getSiteScheduleOccurances(state: SupplierStateModel): Function {
      return (schedueId: number) => {
         const filtered = state.siteScheduleOccurances.filter((siteScheduleOccurance) => siteScheduleOccurance.scheduleId === schedueId);
         if (filtered.length > 0) {
            return JSON.parse(JSON.stringify(filtered[0].result));
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getBuildingScheduleOccurance(state: SupplierStateModel): Function {
      return (schedueId: number, id: number) => {
         const filtered = state.buildingScheduleOccurances.filter((buildingScheduleOccurance) => buildingScheduleOccurance.scheduleId === schedueId);
         if (filtered.length > 0) {
            if (filtered[0].result.length > 0) {
               const occurances = filtered[0].result.filter((occurance) => occurance.id === id);
               if (occurances.length > 0) {
                  return JSON.parse(JSON.stringify(occurances[0]));
               }
               return null;
            }
            return false;
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getSiteScheduleOccurance(state: SupplierStateModel): Function {
      return (schedueId: number, id: number) => {
         const filtered = state.siteScheduleOccurances.filter((siteScheduleOccurance) => siteScheduleOccurance.scheduleId === schedueId);
         if (filtered.length > 0) {
            if (filtered[0].result.length > 0) {
               const occurances = filtered[0].result.filter((occurance) => occurance.id === id);
               if (occurances.length > 0) {
                  return JSON.parse(JSON.stringify(occurances[0]));
               }
               return null;
            }
            return false;
         } else {
            return null;
         }
      };
   }

   @Selector()
   static getBuildingScheduleOccuranceStatus(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number, category: number, status: ScheduleOccurrenceStatus) => {
         for (const schedule of state.buildingScheduleOccurances) {
            for (const occurance of schedule.result) {
               if (occurance.id === Number(scheduleOccuranceId)) {
                  return occurance.scheduleOccurrenceStatus === status;
               }
            }
         }
         return false;
      };
   }

   @Selector()
   static getSiteScheduleOccuranceStatus(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number, category: number, status: ScheduleOccurrenceStatus) => {
         for (const schedule of state.siteScheduleOccurances) {
            for (const occurance of schedule.result) {
               if (occurance.id === Number(scheduleOccuranceId)) {
                  return occurance.scheduleOccurrenceStatus === status;
               }
            }
         }
         return false;
      };
   }

   @Selector()
   static getAssetScheduleOccuranceStatus(state: SupplierStateModel): Function {
      return (scheduleOccuranceId: number, status: ScheduleOccurrenceStatus) => {
         for (const schedule of state.assetScheduleOccurances) {
            for (const occurance of schedule.result) {
               if (occurance.id === Number(scheduleOccuranceId)) {
                  return occurance.scheduleOccurrenceStatus === status;
               }
            }
         }
         return false;
      };
   }
}
