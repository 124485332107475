<nz-avatar
   nz-popover
   nzPopoverTrigger="click"
   [nzPopoverContent]="userMenuContent"
   nzPopoverPlacement="leftTop"
   nzText="{{ userInitials }}"
   [nzSrc]="avatarUrl"
   style="cursor: pointer; color: #3aa1d5; background-color: #ddf2f9;"
   class="ml-2 mb-1"
></nz-avatar>

<ng-template #userMenuContent>
   <div class="text-center m-3">
      <nz-avatar [nzSrc]="avatarUrl" nzText="{{ avatarUrl ? '' : userInitials }}" style="color: #3aa1d5; background-color: #ddf2f9;"></nz-avatar>
      <div class="text-center">
         <div style="font-weight: 500;" class="mt-2">{{ userProfile.firstName }} {{ userProfile.lastName }}</div>
         <div>{{ userProfile.email }}</div>
         <a nz-button class="btn btn-blue Single-button mt-2" nzShape="round" nzType="primary" (click)="viewUserSetting(userProfile.userIdentifier)">
            <span nz-icon nzType="setting" nzTheme="outline"></span>User Setting
         </a>
         <a nz-button class="btn btn-red Single-button mt-2" nzShape="round" nzType="primary" (click)="onLogout()" [state]="{ local: true }" title="Logout">
            <em nz-icon nzType="logout" nzTheme="outline"></em> Logout
         </a>
      </div>
   </div>
</ng-template>
