import { UserProfile } from './Models';

export function getEnumDisplayName(enumValue: string): string {
   const result = enumValue.replace(/([A-Z])/g, ' $1');
   return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getFilesizeInMbs(sizeInBytes: number): number {
   return sizeInBytes / (1024 * 1024);
}

export function getFileIconByType(fileExtension: string): string {
   let fileIconName: string;
   switch (fileExtension) {
      case '.pdf':
         fileIconName = 'file-pdf';
         break;
      case '.docx':
         fileIconName = 'file-word';
         break;
      case '.xlsx':
         fileIconName = 'file-excel';
         break;
      case '.pptx':
         fileIconName = 'file-ppt';
         break;
      case '.txt':
         fileIconName = 'file-text';
         break;
      case '.zip':
         fileIconName = 'file-zip';
         break;
      case '.jpg':
         fileIconName = 'file-image';
         break;
      case '.png':
         fileIconName = 'file-image';
         break;
      case '.PNG':
         fileIconName = 'file-image';
         break;
      case '.jpeg':
         fileIconName = 'file-image';
         break;
      default:
         fileIconName = 'file-unknown';
         break;
   }
   return fileIconName;
}

export function getCurrentUser(): UserProfile {
   return JSON.parse(localStorage.getItem('fulluserprofile'));
}

export function base64ToArrayBuffer(fileContent) {
   const binaryString = window.atob(fileContent);
   const binaryLen = binaryString.length;
   const fileBytes = new Uint8Array(binaryLen);
   for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      fileBytes[i] = ascii;
   }
   return fileBytes;
}

export function downloadFile(fileResult: any) {
   const arrBuffer = base64ToArrayBuffer(fileResult.fileContent);
   const newBlob = new Blob([arrBuffer], {
      type: fileResult.file.contentType,
   });
   const navigatorAny: any = navigator;

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (navigatorAny.msSaveOrOpenBlob) {
         navigatorAny.msSaveOrOpenBlob(newBlob);
         return;
      }
   const fileData = window.URL.createObjectURL(newBlob);
   const link = document.createElement('a');
   document.body.appendChild(link);
   link.href = fileData;
   link.download = fileResult.file.originalFileName + fileResult.file.fileExtention;
   link.click();
   window.URL.revokeObjectURL(fileData);
   link.remove();
}
