import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
   selector: 'app-file-upload',
   templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {
   @Input() documentCategory;
   @Input() fileList: any;
   @Output() documentUpload: EventEmitter<any> = new EventEmitter<any>();

   acceptedList: string[] = [];
   constructor() {
      this.acceptedList = [
         'image/png',
         'image/jpeg',
         'application/pdf',
         '.doc',
         '.docx',
         'application/msword',
         'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
         '.csv',
         '.xlsx',
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
         'application/vnd.ms-excel',
         'application/vnd.ms-powerpoint',
         'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
         'application/vnd.openxmlformats-officedocument.presentationml.presentation',
         '.mp4',
      ];
   }

   beforeUpload = (file: File) => {
      const fileList: File[] = [];
      this.fileList = fileList.concat(file);
      this.documentUpload.emit(file);
      return false;
   };
}
