export enum UserCategory {
   Buyer = 10,
   Supplier = 20,
}

export enum CompanyCategory {
   Unspecified = 0,
   Public = 10,
   Private = 20,
}

export enum Frequency {
   NonRepeating = 0,
   Daily = 10,
   Weekly = 20,
   BiWeekly = 40,
   EveryThreeWeeks = 60,
   Monthly = 80,
   BiMonthly = 160,
   Quaterly = 240,
   BiAnnually = 480,
   Annualy = 960,
}

export enum ScheduleType {
   Statutory = 10,
   Mandatory = 20,
   Discretionary = 30,
}

export enum EntityType {
   Asset = 10,
   Schedule = 20,
   WorkOrder = 30,
   Activity = 40,
   Building = 50,
   ScheduleOccurrence = 60,
   Message = 70,
   Operative = 80,
   AssetSchedule = 90,
   Site = 100,
}

export enum ServiceType {
   Landscaping = 10,
   WindowCleaning = 20,
   Cleaning = 30,
   Security = 40,
   PostManagement = 50,
   WasteManagement = 60,
   Catering = 70,
   CarParking = 80,
}

export enum FileCategory {
   Report = 10,
   RiskAssessment = 20,
   MethodStatement = 30,
   Manual = 40,
   OperatingProcedure = 50,
   ActivityReport = 60,
   ActivityInvoice = 70,
   AssetList = 80,
   ActivityPocedure = 140,
}

export enum ActivityStatus {
   Planned = 10,
   Inprogress = 20,
   Completed = 30,
   Cancelled = 40,
}

export enum ActivityType {
   Scheduled = 10,
   UnScheduled = 20,
}

export enum AssetState {
   NotSet = 0,
   Active = 10,
   Inactive = 20,
   Decommissioned = 30,
}

export enum Tenure {
   Freehold = 10,
   Leasehold = 20,
}

export enum ScheduleOccurrenceStatus {
   Scheduled = 10,
   InProgress = 20,
   Completed = 30,
   Approved = 40,
   Confirmed = 50,
   Accepted = 60,
   Rejected = 70,
   PendingDocumentation = 80,
   Rescheduled = 90,
}

export enum OccurrenceEntityType {
   AssetScheduleOccurrence = 10,
   BuildingScheduleOccurrence = 20,
   SiteScheduleOccurrence = 30,
}

export enum MessageType {
   AssetScheduleMessage = 10,
   BuildingScheduleMessage = 20,
   SiteScheduleMessage = 30,
}

export enum ScheduleFMType {
   Default = 0,
   HardFM = 10,
   SoftFM = 20,
}

export interface EnumVm {
   value: any;
   name: string;
}

export enum BuildingFacilityType {
   Office = 10,
   Commercial = 20,
   DataCentre = 30,
   Miscellaneous = 40,
   Retail = 50,
   Industrial = 60,
   Residential = 70,
   HotelAndLeisure = 80,
   Healthcare = 90,
   Learning = 100,
   PublicBuildings = 110,
}

export enum BulkOperationItemStatus {
   Success = 10,
   Error = 20,
   NoAction = 30,
}

export enum VettingStatus {
   Pending = 10,
   Approved = 20,
   Rejected = 30,
}

export enum InductionStatus {
   New = 10,
   Approved = 20,
}

export enum OperativeType {
   Internal = 10,
   External = 20,
}

export enum ApplicationType {
   Common = 10,
   Compliance = 20,
   Task = 30,
}

export enum ScheduleCategory {
   Activity = 10,
   Task = 20,
}

export enum SyncTypes {
   StartBuildingJob = 10,
   CompleteBuildingJob = 20,
   BuildingDocumentStaging = 30,
   UpdateOccurance = 40,
   BuildingUploadQuotation = 50,
   BuildingUploadCertificate = 60,
   BuildingUploadInvoice = 70,
   BuildingSendMessages = 80,
   StartSiteJob = 90,
   UploadActivityProcedure = 100,
   SiteDocumentStaging = 110,
   SiteUploadQuotation = 120,
   UpdateSiteOccurance = 130,
   SiteUploadCertificate = 140,
   SiteUploadInvoice = 150,
   CompleteSiteJob = 160,
   SiteSendMessages = 170,
   startAssetJob = 180,
   AssetSendMessage = 190,
   AssetDocumentStaging = 200,
   AssetUploadQuotation = 210,
   UpdateAssetOccurance = 220,
   AssetUploadCertificate = 230,
   AssetUploadInvoice = 240
}
