import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Confirmation } from './confirmation';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationPopupService {
    //#region Public declarations
    public selection: string = '';
    public resultSubject$ = new Subject();
    public confirmationPopupSubject$ = new Subject<Confirmation>();
    public confirmationPopup$ = this.confirmationPopupSubject$.asObservable();
    //#endregion

    constructor() {}

    //#region Public methods
    public confirm(confirmation: Confirmation) {
        this.confirmationPopupSubject$.next(confirmation);
        return this;
    }

    public warning(confirmation: Confirmation) {
        this.confirmationPopupSubject$.next(confirmation);
    }
    //#endregion
}
