import * as tt from '@tomtom-international/web-sdk-maps';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import * as turf from '@turf/turf';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-geo-fencing',
  templateUrl: './geo-fencing.component.html',
  styleUrls: ['./geo-fencing.component.css']
})
export class GeoFencingComponent implements AfterViewInit{

  constructor(private httpClient: HttpClient){
  }
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  title = 'tomtom-geofence';
  map!: tt.Map; // Declare map here
  ngAfterViewInit(): void{
    this.map = tt.map({
      key: 'bPRmAICW0IswNY1qsT8pNzX0uuwXuWWU',
      container: this.mapContainer.nativeElement,
      zoom: 5,
      center: [-1.286389, 36.817223],
    });
 
    this.map.addControl(new tt.FullscreenControl()); 
    this.map.addControl(new tt.NavigationControl()); 

    this.map.on('click', (event) => {
      const position = event.lngLat;
      const URL = `https://api.tomtom.com/geofencing/1/projects/70803407-8569-4d72-a548-86b8c9b5e878/fence?key=NVMmQGlhqferpMQinQjP9yZUX06e27Ar&adminKey=0lYXcCBLqK60Ar0PrXCLDXBRbvKP8m4jIU1C7pLTlzgF6Hsk`;
      const payload = {
        name: `Our location ${Math.floor(Math.random() * 100)}`,
        type: 'Feature',
        geometry: {
          radius: 85,
          type: 'Point',
          shapeType: 'Circle',
          coordinates: [position.lat, position.lng],
        }
      }

      this.httpClient.post(URL, payload).subscribe((result) => {
        let _center = turf.point([position.lng, position.lat]);
        let _redius = 45;
        let _options = {};
        let _circle = turf.circle(_center, _redius, _options);
        this.map.addSource('circlularFence', {
          type: 'geojson',
          data: _circle,
        });
        this.map.addLayer({
          id: 'circle-fill',
          type: 'fill',
          source: 'circularFence',
          paint: {
            'fill-color' : 'blue',
            'fill-opacity': 0.5
          }
        })
      })
    });
}
}