<nz-result
   nzStatus="success"
   nzTitle="FM Navigate"
   nzSubTitle="Login Success, you are being redirected to your home page"
>
</nz-result>
<ng-template #indicatorTemplate
   ><em nz-icon nzType="loading" style="font-size: 50px;"></em>
</ng-template>
<div style="text-align: center;">
   <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
</div>
