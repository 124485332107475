import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { UserService } from '../../../admin/_services/user.service';
import { UserProfile } from '../../Models';
import { UserType } from '../../Enums';
import { getEnumDisplayName } from '../../../compliance/evbex-common/Utils';
import { UserSettingService } from '../../../admin/_services/usersetting.service';
import { NotificationService } from '../../notification.service';
import { FileResult } from '../../../admin/_entities/FileResult';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApplicationTenant } from '../../../admin/_entities/ApplicationTenant';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PasswordResetModel } from '../../../admin/_entities/PasswordResetModel';

@Component({
   selector: 'app-change-password',
   templateUrl: './change-password.component.html',
   styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
   @Input() fileList: any;
   @Output() documentUpload: EventEmitter<any> = new EventEmitter<any>();
   showUserSettingDrawer = false;
   acceptedList: string[] = [];
   avatarUrl?: any;
   loading = false;
   userIdentifier: string; // Example initials
   userProfilePicture: string | undefined;
   userProfile: UserProfile | any = {};
   validateForm: UntypedFormGroup;
   emailForm: UntypedFormGroup;
   modules: string = '';
   isSaving = false;
   razorViewContent: string;
   emailFormVisible = false;
   constructor(
      private fb: UntypedFormBuilder,
      private msg: NzMessageService,
      private activatedRoute: ActivatedRoute,
      private userService: UserService,
      private userSettingService: UserSettingService,
      private notificationService: NotificationService,
      private location: Location,
      private http: HttpClient,
      private router: Router
   ) {
      this.acceptedList = ['image/png', 'image/jpeg'];
   }
   /* beforeUpload = (file: File) => {
    const fileList: File[] = [];
    this.fileList = fileList.concat(file);
    this.documentUpload.emit(file);
    return false;
 };

 private getBase64(img: File, callback: (img: string) => void): void {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result!.toString()));
  reader.readAsDataURL(img);
}

handleChange(info: { file: NzUploadFile }): void {
  this.getBase64(info.file!.originFileObj!, (img: string) => {
    this.loading = false;
    this.avatarUrl = img;
  });
}*/

   beforeUpload = (file: File, _fileList: NzUploadFile[]): Observable<boolean> =>
      new Observable((observer: Observer<boolean>) => {
         const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
         if (!isJpgOrPng) {
            this.msg.error('You can only upload JPG file!');
            observer.complete();
            return;
         }
         const isLt2M = file.size! / 1024 / 1024 < 2;
         if (!isLt2M) {
            this.msg.error('Image must smaller than 2MB!');
            observer.complete();
            return;
         }
         observer.next(isJpgOrPng && isLt2M);
         observer.complete();
         this.userSettingService.uploadUserProfilePic(this.userProfile.tenantId, this.userProfile.id, this.fileList[0]).subscribe(
            (data) => {
               this.fileList = [];
               this.notificationService.success('User Profile uploaded');
            },
            (error) => {
               this.notificationService.error('Upload failed');
               console.error(error);
            }
         );
      });

   private getBase64(img: File, callback: (img: string) => void): void {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result!.toString()));
      reader.readAsDataURL(img);
   }

   navigateToResetPassword() {
      this.emailFormVisible = true;
   }

   handleChange(info: { file: NzUploadFile }): void {
      this.getBase64(info.file!.originFileObj!, (img: string) => {
         this.loading = false;
         this.avatarUrl = img;
      });
      const fileList: File[] = [];
      this.fileList = fileList.concat(info.file!.originFileObj!);
      const fileUpload = info.file as any;
   }
   ngOnInit(): void {
      this.validateForm = this.fb.group({
         firstName: [null],
         surname: [null],
         email: [null],
         phoneNumber: [null],
         designation: [null],
         userType: [null],
         modules: [null],
      });
      this.emailForm = this.fb.group({
         email: [null],
      });
      const id = this.activatedRoute.snapshot.paramMap.get('id')?.toString();
      if (id) {
         this.getActiceUser(id);
      }
   }

   getActiceUser(userId) {
      this.userService.getUserDetails(userId).subscribe(
         (user) => {
            this.userProfile = user;
            this.userProfile.userTypeName = getEnumDisplayName(UserType[this.userProfile.userType]);

            this.userSettingService.getuserProfilePic(this.userProfile.tenantId, this.userProfile.id).subscribe((file: FileResult) => {
               if (file !== null) {
                  //const byteData: Uint8Array = file.fileContent;
                  const blob = new Blob([file.fileContent], { type: 'image/jpeg' });
                  //const blobParts: BlobPart[] = [blob];
                  const fileName = file.file.originalFileName + file.file.fileExtention;
                  const fileNew = new File([blob], fileName);
                  this.getBase64(fileNew, (img: string) => {
                     const imgDataUrl = `data:${file.file.contentType};base64,${file.fileContent}`;

                     this.loading = false;
                     this.avatarUrl = imgDataUrl;
                  });
               }
            });

            this.userSettingService.getApplications(this.userProfile.tenantId).subscribe((applicationTenants: ApplicationTenant[]) => {
               applicationTenants.forEach((applicationTenant: ApplicationTenant) => {
                  this.modules = this.modules + applicationTenant.application.applicationName + ', ';
               });
               this.validateForm.get('modules')?.setValue(this.modules);
            });
         },
         (err) => {
            console.error(err);
         }
      );
   }

   setUserProfileData(userProfile: UserProfile) {
      if (userProfile) {
         this.validateForm.get('firstName')?.setValue(userProfile.firstName);
         this.validateForm.get('surname')?.setValue(userProfile.lastName);
         this.validateForm.get('email')?.setValue(userProfile.email);
         this.validateForm.get('phoneNumber')?.setValue(userProfile.phoneNumber);
         this.validateForm.get('designation')?.setValue(userProfile.position);
         this.validateForm.get('userType')?.setValue(userProfile.userTypeName);
      }
   }

   submitForm() {
      var model = new PasswordResetModel();
      model.email = this.emailForm.get('email')?.value;
      model.name = this.userProfile.firstName;
      model.profile = this.userProfile;
      this.userSettingService.resetPassword(model).subscribe(
         (data) => {
            this.notificationService.success('Sent an email. Please check');
         },
         (error) => {
            console.error(error);
            this.notificationService.failure();
         }
      );
      this.emailFormVisible = false;
   }

   closeForm() {
      this.emailFormVisible = false;
   }
   openUserSettingsDetail() {
      this.showUserSettingDrawer = true;
      this.setUserProfileData(this.userProfile);
   }

   closeUserSettingDrawer() {
      this.showUserSettingDrawer = false;
   }

   updateUserProfileDetail() {
      this.userProfile.firstName = this.validateForm.get('firstName')?.value;
      this.userProfile.lastName = this.validateForm.get('surname')?.value;
      this.userProfile.email = this.validateForm.get('email')?.value;
      this.userProfile.phoneNumber = this.validateForm.get('phoneNumber')?.value;
      this.userProfile.position = this.validateForm.get('designation')?.value;

      this.userService.updateUserProfile(this.userProfile).subscribe(
         (userPrfile: UserProfile) => {
            this.userProfile = userPrfile;
            this.notificationService.updatingSuccess('User Profile');
            this.showUserSettingDrawer = false;
         },
         (error) => {
            this.notificationService.updatingError('User Profile');
            console.error(error);
            this.showUserSettingDrawer = false;
         }
      );
   }
   onBack() {
      this.location.back();
   }
}
