<router-outlet></router-outlet>
<nz-modal
         [(nzVisible)]="idleVisible"
         nzTitle="Session Expired"
         nzOkText="OK"
         (nzOnOk)="accept()"
      >
         <p>
            Session expired. You will be redirected to login page
         </p>
</nz-modal>
